import React, { useEffect, useState } from "react";
import { setSnackbarMessage } from "../../../store/slices/snackBarSlice";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import classes from "./currentVisits.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { dateConverter } from "../../../utils/dateConverter";
import SurveyAndOrder from "./SurveyAndOrder";
import CustomStagesPieChart from "../customStagePieChart/CustomStagesPieChart";
import StageTable from "./StageTable";
import ScheduleStudyDialog from "../../misc/ScheduleStudyDialog";
import VerifyInsuranceDialog from "../../misc/VerifyInsuranceDialog";
import GetAuthorizationDialog from "../../misc/GetAuthorizationDialog";
import VisitsFile from "./VisitsFile";
import ConsentFormsDialog from "../../misc/ConsentFormsDialog";
import UpdateStudyInfoDialog from "../../misc/UpdateStudyInfoDialog";
import ScoreStudyDialog from "../../misc/ScoreStudyDialog";
import UploadInterpretationDialog from "../../misc/UploadInterpretationDialog";
import TreatmentAppointmentDialog from "../../misc/TreatmentAppointmentDialog";
import DiscussResultsDialog from "../../misc/DiscussResultsDialog";
import ResultsFollowupDialog from "../../misc/ResultsFollowupDialog";
import moment from "moment";
import { dmeOrderPostData,orderPostData, visitsGetDmeStudyData, visitsGetStudyData } from "../../../axios/api";
import { useAxios } from "../../../hooks/auth.js";
import WaitingScheduleDialog from "../../misc/WaitingScheduleDialog";
import RescheduleStudyDialog from "../../misc/RescheduleStudyDialog";
import InterpretationAvailableDialog from "../../misc/InterpretationAvailableDialog.js";
import FormTable from '../../../components/patientPortalComponent/FormTable';
import OrderInfoDialog from "../../misc/OrderInfoDialog.js";
import TreatmentScheduleStudyDialog from "../../misc/TreatmentScheduleStudyDialog.js";
import DmeVerifyInsuranceDialog from "../../misc/DmeVerifyInsuranceDialog.js";
import DmeUpdateStudyInfoDialog from "../../misc/DmeUpdateStudyInfoDialog.js";
import DmeConsentFormsDialog from "../../misc/DmeConsentFormsDialog.js";
import RescheduleCancelDialog from "../../misc/RescheduleCancelDialog.js";

function VisitsAccordian({
  visitsData,
  isPreviousVisits = false,
  isCurrentVisits = false,
  reloadVisit,
  isWellnecessitiesUser
}) {
 
  const axios = VisitsAccordian.useAxios();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:800px)");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(visitsData);
  const [expanded, setExpanded] = useState(isPreviousVisits ? null : 0);
  const [dmeOrderData,setDmeOrderData] = useState([])

  const [orderData,setOrderData] = useState([])
  const [prevExpanded, setPrevExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setPrevExpanded(isExpanded)
  };

  
  useEffect(() => {
    if (visitsData?.length !== 0) {
      const updateFunct = async () => {
        let newArr = visitsData.map(async (item) => {
        
          if (item.studyId) {
            try {
              if(item.dmeOrderId > 0 && !item?.orderId){
                const res = await visitsGetDmeStudyData(axios, "viewStudy", {
                  studyId: item.studyId,
                });
         
                item.studyData = res[0];
             
              }else{
                const res = await visitsGetStudyData(axios, "viewStudy", {
                  studyId: item.studyId,
                });
         
                item.studyData = res[0];
              }
            } catch (err) {
              console.log("err==>", err);
            } finally {
              setIsLoading(false);
            }
            return item;
          } else {

            if (item?.origOrderId) item.orderInfo = 1;
  
            return item;
          }
        });
        const arr = await Promise.all(newArr);
        setData(arr);

        if(arr[0]?.origDmeOrderId > 0 && !arr[0]?.origOrderId && !arr[0]?.orderIdOrder){
          const payload = {
            dmeOrderId: arr[0]?.dmeOrderId || arr[0]?.origDmeOrderId,
          };
          dmeOrderPostData(axios, "viewDmeOrder", payload)
            .then((res) => {
              if (res?.data?.length) {
                setDmeOrderData(res.data[0])
                    }
            })
            .catch((err) => { });
        }

        if((arr[0]?.orderId || arr[0]?.origOrderId) && isCurrentVisits){
          const payload = {
            orderId: arr[0]?.orderId || arr[0]?.origOrderId,
          };
          orderPostData(axios, "viewOrder", payload)
            .then((res) => {
              if (res?.data?.length) {
                setOrderData(res.data[0])
              }
            })
            .catch((err) => { });
        }
      };
      updateFunct();
    }
  }, [visitsData]);


  const handleStudyComponent = (item, stageId, data) => {
    return (
      <>
        {/* Appointment Information:  */}


          {data?.origDmeOrderId > 0 && !data?.origOrderId && !data?.orderIdOrder ? "":
          <>
            <Box
              sx={{
                border: "1px solid #d0d6d1",
                marginTop: "20px",
              }}
            />
            <Box>
              <ScheduleStudyDialog
                notShowPopup={true}
                notificationToastSuccess={(message) =>
                  dispatch(setSnackbarMessage({ message, type: "success" }))
                }
                notificationToastError={(message) =>
                  dispatch(setSnackbarMessage({ message, type: "error" }))
                }
                asDialog={false}
                isPreviousVisits={isPreviousVisits}
                reloadCurrentVisit={reloadVisit}
                isWellnecessitiesUser={isWellnecessitiesUser}
                studyData={{
                  studyId: item?.studyId,
                  physicianId: item?.physicianId,
                  patientId: item?.patientId || item?.origPatientId,
                  surveyId: item?.surveyId,
                  orderId: item?.orderId || item?.origOrderId,
                  studyTypeId: item?.studyTypeId,
                  orderingPhysicianName: item?.orderingPhysicianName,
                  prefInterpPhysId: item?.prefInterpPhysId,
                  studyDate: item?.date,
                  shipDate: item?.shipDate,
                  trackingNum: item?.trackingNum,
                  outTrackingNum: item?.outTrackingNum,
                  locationId: item?.locationId,
                  time: item?.time,
                  deviceReturnDate: item?.deviceReturnDate,
                  hstDeviceId: item?.hstDeviceId,
                  pickup: item?.pickup,
                  hstSerialNumber: item?.hstSerialNumber,
                }}
              />
            </Box>
          </>
          }

          {/* reschedule cancel study */}
        <Box
          sx={{
            border: "1px solid #d0d6d1",
            marginTop: "20px",
          }}
        />
        <Box>
          <RescheduleCancelDialog
            notShowPopup={true}
            asDialog={false}
            isPreviousVisits={isPreviousVisits}
            isWellnecessitiesUser={isWellnecessitiesUser}
            rescheduleData={{
              physicianId: item?.physicianId,
              patientId: item?.patientId || item?.origPatientId,
              surveyId: item?.surveyId,
              orderId: item?.orderId || item?.origOrderId,
              stageId: item?.stageId || stageId,
              prefInterpPhysId: item?.prefInterpPhysId,
              date: item?.date,
              studyTypeId: item?.studyTypeId,
              locationId: item?.locationIdStudy,
              SHIP: item?.shipDate,
              trackingNum: item?.trackingNum,
              studyId: item?.studyId,
              time: item?.time,
              reasonReschedulingId: item?.reasonReschedulingId,
              reasonCancelingId: item?.reasonCancelingId,
              hstDeviceId: item?.hstDeviceId,
              reasonAuthDenial: item?.reasonAuthorDenial,
              deviceReturnDate: item?.deviceReturnDate,
              requestedReschedule: item?.requestedReschedule,
              primaryInsuranceId: item?.primaryInsuranceIdP,
              secondaryInsuranceId: item?.secondaryInsuranceIdP,
              primaryPolicy: item?.primaryPolicyP,
              primaryGroup: item?.primaryGroupP,
              secondaryPolicy: item?.secondaryPolicyP,
              secondaryGroup: item?.secondaryGroupP,
              requestedCancelStudy: item?.requestedCancelStudy,
              pickup: item?.pickup,
              outTrackingNum: item?.outTrackingNum,
              dmeOrderId: item?.dmeOrderIdOrder,
              shipDate: item?.shipDate,
              dmeOrderId: item?.dmeOrderId,
              dmeBrandId: item?.dmeBrandId,
              dmeModelId: item?.dmeModelId
            }}
            notificationToastSuccess={(message) =>
              dispatch(setSnackbarMessage({ message, type: "success" }))
            }
            notificationToastError={(message) =>
              dispatch(setSnackbarMessage({ message, type: "error" }))
            }
          />
        </Box>
          
        {/* reschedule study */}
        <Box
          sx={{
            border: "1px solid #d0d6d1",
            marginTop: "20px",
          }}
        />
        <Box>
          <RescheduleStudyDialog
            notShowPopup={true}
            asDialog={false}
            isPreviousVisits={isPreviousVisits}
            isWellnecessitiesUser={isWellnecessitiesUser}
            rescheduleData={{
              studyId: item?.studyId,
              physicianId: item?.physicianId,
              patientId: item?.patientId || item?.origPatientId,
              orderId: item?.orderId || item?.origOrderId,
              studyTypeId: item?.studyTypeId,
              trackingNum: item?.trackingNum,
              outTrackingNum: item?.outTrackingNum,
              locationId: item?.locationId,
              time: item?.time,
              stageId: stageId,
              SDATE: item.date,
              SHIP: item?.shipDate,
              reasonReschedulingId: item?.reasonReschedulingId,
              canceled: item?.canceled || item?.requestedCancelStudy,
              reasonCancelingId: item?.reasonCancelingId,
              hstDeviceId: item?.hstDeviceId,
              reasonAuthDenial: item?.reasonAuthorDenial,
              deviceReturnDate: item?.deviceReturnDate,
              prefInterpPhysId: item?.prefInterpPhysId,
              reschedule: item?.reschedule || item?.requestedReschedule,
              pickup: item?.pickup,
              reasonIncomplete: item?.reasonIncomplete,
              reasonApptCanc: item?.reasonApptCanc,
              reasonApptResched: item?.reasonApptResched,
              dmeOrderId: item?.dmeOrderId,
              dmeBrandId: item?.dmeBrandId,
              dmeModelId: item?.dmeModelId
            }}
            notificationToastSuccess={(message) =>
              dispatch(setSnackbarMessage({ message, type: "success" }))
            }
            notificationToastError={(message) =>
              dispatch(setSnackbarMessage({ message, type: "error" }))
            }
          />
        </Box>

        {/* Inssurance and EVPA Information */}
        <Box
          sx={{
            border: "1px solid #d0d6d1",
            marginTop: "20px",
          }}
        />
        <Box>
          {data?.origDmeOrderId > 0 && !data?.origOrderId && !data?.orderIdOrder ?
            <DmeVerifyInsuranceDialog
              notShowPopup={true}
              // handleSave={verifyInsurance}
              // handleClose={verifyInsuranceDialog}
              isWellnecessitiesUser={isWellnecessitiesUser}
              inssuranceData={{
                physicianId: item?.physicianId,
                patientId: item?.patientId || item?.origPatientId,
                surveyId: item?.surveyId,
                orderId: item?.orderId || item?.origOrderId,
                studyTypeId: item?.studyTypeId,
                stageId: item?.stageId,
                studyId: item?.studyId,
                primaryInsuranceId: item?.primaryInsuranceId,
                primaryInsurance: item?.primaryInsurance,
                secondaryInsuranceId: item?.secondaryInsuranceId,
                secondaryInsurance: item?.secondaryInsurance,
                primaryPolicy: item?.primaryPolicy,
                secondaryPolicy: item?.secondaryPolicy,
                primaryGroup: item?.primaryGroup,
                secondaryGroup: item?.secondaryGroup,
                shipDate: item?.shipDate,
                trackingNum: item?.trackingNum,
                planNameP: item?.planNameP,
                planNameS: item?.planNameS,
                studyTypeName: item?.studyTypeName,
                placeOfService: item?.placeOfService,
                insVerified: item?.insVerified,
                VDATE: item?.insVerifiedDate,
                cptsVerified: item?.cptsVerified,
                verifiedContact: item?.verifiedContact,
                policyType: item?.policyType,
                planAdmin: item?.planAdmin,
                policyHolder: item?.policyHolder,
                PDATE: item?.policyHolderDob,
                spokeTo: item?.spokeTo,
                referenceNum: item?.referenceNum,
                planType: item?.planType,
                EDATE: item?.policyEffDate,
                deductible: item?.deductible,
                deductibleMet: item?.deductibleMet,
                oop: item?.oop,
                oopMet: item?.oopMet,
                coIns: item?.coIns,
                copay: item?.copay,
                inNetwork: item?.inNetwork,
                applyDeductible: item?.applyDeductible,
                deductibleYear: item?.deductibleYear,
                referralRequired: item?.referralRequired,
                referralRequiredFrom: item?.referralRequiredFrom,
                priorAuthRequired: item?.priorAuthRequired,
                parContact: item?.parContact,
                claimAddress: item?.claimAddress,
                insVerifiedDate: item?.insVerifiedDate,
                deductible2: item?.deductible2,
                deductibleMet2: item?.deductibleMet2,
                oop2: item?.oop2,
                oopMet2: item?.oopMet2,
                coIns2: item?.coIns2,
                copay2: item?.copay2,
                inNetwork2: item?.inNetwork2,
                applyDeductible2: item?.applyDeductible2,
                deductibleYear2: item?.deductibleYear2,
                policyEffDate: item?.policyEffDate
                  ? moment(item?.policyEffDate).format("YYYY-MM-DD")
                  : "",
                policyEffDate2: item?.policyEffDate2
                  ? moment(item?.policyEffDate2).format("YYYY-MM-DD")
                  : "",
                verifiedPrimary: item?.verifiedPrimary,
                verifiedSecondary: item?.verifiedSecondary,
                patientNameVal: item.patientName,
                meditechAcctNum: item?.meditechAcctNum,
                policySelf: item?.policySelf,
                dob: item?.dob,
                npi: item?.npi,
                npi2: item?.npi2,
                taxId: item?.taxId,
                taxId2: item?.taxId2,
                tertiaryInsuranceId: item?.tertiaryInsuranceId,
                tertiaryPolicy: item?.tertiaryPolicy,
                tertiaryGroup: item?.tertiaryGroup,
                planNameT: item?.planNameT,
                deductible3: item?.deductible3,
                deductibleMet3: item?.deductibleMet3,
                oop3: item?.oop3,
                oopMet3: item?.oopMet3,
                coIns3: item?.coIns3,
                copay3: item?.copay3,
                inNetwork3: item?.inNetwork3,
                applyDeductible3: item?.applyDeductible3,
                deductibleYear3: item?.deductibleYear3,
                policyEffDate3: item?.policyEffDate3
                  ? moment(item?.policyEffDate3).format("YYYY-MM-DD")
                  : "",
                verifiedTertiary: item?.verifiedTertiary,
                priorAuthRequired2: item?.priorAuthRequired2,
                priorAuthRequired3: item?.priorAuthRequired3,
                policyType2: item?.policyType2,
                planAdmin2: item?.planAdmin2,
                spokeTo2: item?.spokeTo2,
                referenceNum2: item?.referenceNum2,
                policyType3: item?.policyType3,
                planAdmin3: item?.planAdmin3,
                spokeTo3: item?.spokeTo3,
                referenceNum3: item?.referenceNum3,
                dmeOrderId: item?.dmeOrderId,
              }}
              // inssuranceData={item}
              asDialog={false}
              isPreviousVisits={isPreviousVisits}
              reloadCurrentVisit={reloadVisit}
              // setViewCloseDialogState={(state)=>{setViewVerifyInsuranceDialogState(state); setRefreshData(!refreshData)}}
              notificationToastSuccess={(message) =>
                dispatch(setSnackbarMessage({ message, type: "success" }))
              }
              notificationToastError={(message) =>
                dispatch(setSnackbarMessage({ message, type: "error" }))
              }
            />
          :
            <VerifyInsuranceDialog
              notShowPopup={true}
              // handleSave={verifyInsurance}
              // handleClose={verifyInsuranceDialog}
              isWellnecessitiesUser={isWellnecessitiesUser}
              inssuranceData={{
                physicianId: item?.physicianId,
                patientId: item?.patientId || item?.origPatientId,
                surveyId: item?.surveyId,
                orderId: item?.orderId || item?.origOrderId,
                studyTypeId: item?.studyTypeId,
                stageId: item?.stageId,
                studyId: item?.studyId,
                primaryInsuranceId: item?.primaryInsuranceId,
                primaryInsurance: item?.primaryInsurance,
                secondaryInsuranceId: item?.secondaryInsuranceId,
                secondaryInsurance: item?.secondaryInsurance,
                primaryPolicy: item?.primaryPolicy,
                secondaryPolicy: item?.secondaryPolicy,
                primaryGroup: item?.primaryGroup,
                secondaryGroup: item?.secondaryGroup,
                shipDate: item?.shipDate,
                trackingNum: item?.trackingNum,
                planNameP: item?.planNameP,
                planNameS: item?.planNameS,
                studyTypeName: item?.studyTypeName,
                placeOfService: item?.placeOfService,
                insVerified: item?.insVerified,
                VDATE: item?.insVerifiedDate,
                cptsVerified: item?.cptsVerified,
                verifiedContact: item?.verifiedContact,
                policyType: item?.policyType,
                planAdmin: item?.planAdmin,
                policyHolder: item?.policyHolder,
                PDATE: item?.policyHolderDob,
                spokeTo: item?.spokeTo,
                referenceNum: item?.referenceNum,
                planType: item?.planType,
                EDATE: item?.policyEffDate,
                deductible: item?.deductible,
                deductibleMet: item?.deductibleMet,
                oop: item?.oop,
                oopMet: item?.oopMet,
                coIns: item?.coIns,
                copay: item?.copay,
                inNetwork: item?.inNetwork,
                applyDeductible: item?.applyDeductible,
                deductibleYear: item?.deductibleYear,
                referralRequired: item?.referralRequired,
                referralRequiredFrom: item?.referralRequiredFrom,
                priorAuthRequired: item?.priorAuthRequired,
                parContact: item?.parContact,
                claimAddress: item?.claimAddress,
                insVerifiedDate: item?.insVerifiedDate,
                deductible2: item?.deductible2,
                deductibleMet2: item?.deductibleMet2,
                oop2: item?.oop2,
                oopMet2: item?.oopMet2,
                coIns2: item?.coIns2,
                copay2: item?.copay2,
                inNetwork2: item?.inNetwork2,
                applyDeductible2: item?.applyDeductible2,
                deductibleYear2: item?.deductibleYear2,
                policyEffDate: item?.policyEffDate
                  ? moment(item?.policyEffDate).format("YYYY-MM-DD")
                  : "",
                policyEffDate2: item?.policyEffDate2
                  ? moment(item?.policyEffDate2).format("YYYY-MM-DD")
                  : "",
                verifiedPrimary: item?.verifiedPrimary,
                verifiedSecondary: item?.verifiedSecondary,
                patientNameVal: item.patientName,
                meditechAcctNum: item?.meditechAcctNum,
                policySelf: item?.policySelf,
                dob: item?.dob,
                npi: item?.npi,
                npi2: item?.npi2,
                taxId: item?.taxId,
                taxId2: item?.taxId2,
                tertiaryInsuranceId: item?.tertiaryInsuranceId,
                tertiaryPolicy: item?.tertiaryPolicy,
                tertiaryGroup: item?.tertiaryGroup,
                planNameT: item?.planNameT,
                deductible3: item?.deductible3,
                deductibleMet3: item?.deductibleMet3,
                oop3: item?.oop3,
                oopMet3: item?.oopMet3,
                coIns3: item?.coIns3,
                copay3: item?.copay3,
                inNetwork3: item?.inNetwork3,
                applyDeductible3: item?.applyDeductible3,
                deductibleYear3: item?.deductibleYear3,
                policyEffDate3: item?.policyEffDate3
                  ? moment(item?.policyEffDate3).format("YYYY-MM-DD")
                  : "",
                verifiedTertiary: item?.verifiedTertiary,
                priorAuthRequired2: item?.priorAuthRequired2,
                priorAuthRequired3: item?.priorAuthRequired3,
                policyType2: item?.policyType2,
                planAdmin2: item?.planAdmin2,
                spokeTo2: item?.spokeTo2,
                referenceNum2: item?.referenceNum2,
                policyType3: item?.policyType3,
                planAdmin3: item?.planAdmin3,
                spokeTo3: item?.spokeTo3,
                referenceNum3: item?.referenceNum3,
              }}
              // inssuranceData={item}
              asDialog={false}
              isPreviousVisits={isPreviousVisits}
              reloadCurrentVisit={reloadVisit}
              // setViewCloseDialogState={(state)=>{setViewVerifyInsuranceDialogState(state); setRefreshData(!refreshData)}}
              notificationToastSuccess={(message) =>
                dispatch(setSnackbarMessage({ message, type: "success" }))
              }
              notificationToastError={(message) =>
                dispatch(setSnackbarMessage({ message, type: "error" }))
              }
            />
          }
        </Box>

        {/* Authorization info */}
        <Box
          sx={{
            border: "1px solid #d0d6d1",
            marginTop: "20px",
          }}
        />
        <Box>
          <GetAuthorizationDialog
            notShowPopup={true}
            asDialog={false}
            isPreviousVisits={isPreviousVisits}
            reloadCurrentVisit={reloadVisit}
            isWellnecessitiesUser={isWellnecessitiesUser}
            authorizationInfo={{
              physicianId: item?.physicianId,
              patientId: item?.patientId || item?.origPatientId,
              orderId: item?.orderId || item?.origOrderId,
              stageId: stageId,
              prefInterpPhysId: item?.prefInterpPhysId,
              studyId: item?.studyId,
              activeAuthorization: item?.activeAuthorization,
              authorizedOn: item?.authorizedOn,
              authorizationStartDate: item?.authorizationStartDate,
              authorizationEndDate: item?.authorizationEndDate,
              authorizationDenied: item?.authorizationDenied,
              reasonAuthDenial: item?.reasonAuthDenial,
              VDATE: item?.VDATE || item?.insVerifiedDate,
              PDATE: item?.PDATE || item?.policyEffDate,
              EDATE: item?.EDATE || item?.policyHolderDob,
              reasonAuthDenialId: item?.reasonAuthDenialId,
              authNum: item?.authNum,
              followUpAuthorizationDate: item?.followUpAuthorizationDate,
              priorAuthRequired: item?.priorAuthRequired,
              priorAuthRequired2: item?.priorAuthRequired2,
              priorAuthRequired3: item?.priorAuthRequired3,
              activeAuthorization2: item?.activeAuthorization2,
              authorizedOn2: item?.authorizedOn2,
              authNum2: item?.authNum2,
              authorizationStartDate2: item?.authorizationStartDate2,
              authorizationEndDate2: item?.authorizationEndDate2,
              authorizationDenied2: item?.authorizationDenied2,
              reasonAuthDenialId2: item?.reasonAuthDenialId2,
              reasonAuthDenial3: item?.reasonAuthDenial3,
              activeAuthorization3: item?.activeAuthorization3,
              authorizedOn3: item?.authorizedOn3,
              authNum3: item?.authNum3,
              authorizationStartDate3: item?.authorizationStartDate3,
              authorizationEndDate3: item?.authorizationEndDate3,
              authorizationDenied3: item?.authorizationDenied3,
              reasonAuthDenialId3: item?.reasonAuthDenialId3,
              reasonAuthDenial3: item?.reasonAuthDenial3,
              dmeOrderId: item?.dmeOrderId,
            }}
            isCurrentVisits={true}
            notificationToastSuccess={(message) =>
              dispatch(setSnackbarMessage({ message, type: "success" }))
            }
            notificationToastError={(message) =>
              dispatch(setSnackbarMessage({ message, type: "error" }))
            }
          />
        </Box>

        {/* Patient Consent Forms */}
        <Box
          sx={{
            border: "1px solid #d0d6d1",
            marginTop: "20px",
          }}
        />
        <Box>

          {data?.origDmeOrderId > 0 && !data?.origOrderId && !data?.orderIdOrder ?
            <DmeConsentFormsDialog
              notShowPopup={true}
              asDialog={false}
              isWellnecessitiesUser={isWellnecessitiesUser}
              consentInfo={{
                patientId: item?.patientId,
                orderId: item?.orderId,
                stageId: stageId,
                surveyId: item?.surveyId,
                contacAttempt: item?.contacAttempt,
                studyId: item?.studyId,
                consentFormCompleted: item?.consentFormCompleted,
                skipConsentFormCompleted: item?.skipConsentFormCompleted,
                patientConfirmedScheduledStudy: item?.patientConfirmedScheduledStudy,
                patientConfirmedDateTime: item?.patientConfirmedDateTime,
                paymentReceived: item?.paymentReceived,
                studyTypeId: item?.studyTypeId,
                outTrackingNum: item?.outTrackingNum,
                trackingNum: item?.trackingNum,
                hstSerialNumber: item?.hstSerialNumber,
                dmeOrderId: item?.origDmeOrderId,
                dmeBrandId: item?.dmeBrandId,
                dmeModelId: item?.dmeModelId
              }}
              reloadCurrentVisit={reloadVisit}
              isPreviousVisits={isPreviousVisits}
              isCurrentVisits={isCurrentVisits}
              notificationToastSuccess={(message) =>
                dispatch(setSnackbarMessage({ message, type: "success" }))
              }
              notificationToastError={(message) =>
                dispatch(setSnackbarMessage({ message, type: "error" }))
              }
            />
            :
            <ConsentFormsDialog
              notShowPopup={true}
              asDialog={false}
              isWellnecessitiesUser={isWellnecessitiesUser}
              consentInfo={{
                patientId: item?.patientId,
                orderId: item?.orderId,
                stageId: stageId,
                surveyId: item?.surveyId,
                contacAttempt: item?.contacAttempt,
                studyId: item?.studyId,
                consentFormCompleted: item?.consentFormCompleted,
                skipConsentFormCompleted: item?.skipConsentFormCompleted,
                patientConfirmedScheduledStudy: item?.patientConfirmedScheduledStudy,
                patientConfirmedDateTime: item?.patientConfirmedDateTime,
                paymentReceived: item?.paymentReceived,
                studyTypeId: item?.studyTypeId,
                outTrackingNum: item?.outTrackingNum,
                trackingNum: item?.trackingNum,
                hstSerialNumber: item?.hstSerialNumber
              }}
              reloadCurrentVisit={reloadVisit}
              isPreviousVisits={isPreviousVisits}
              isCurrentVisits={isCurrentVisits}
              notificationToastSuccess={(message) =>
                dispatch(setSnackbarMessage({ message, type: "success" }))
              }
              notificationToastError={(message) =>
                dispatch(setSnackbarMessage({ message, type: "error" }))
              }
            />
          }

        </Box>

        {/* Update Study Info */}
        <Box
          sx={{
            border: "1px solid #d0d6d1",
            marginTop: "20px",
          }}
        />
         {data?.origDmeOrderId > 0 && !data?.origOrderId && !data?.orderIdOrder ?
          <DmeUpdateStudyInfoDialog
          notShowPopup={true}
          asDialog={false}
          isPreviousVisits={isPreviousVisits}
          isCurrentVisits={isCurrentVisits}
          reloadCurrentVisit={reloadVisit}
          isWellnecessitiesUser={isWellnecessitiesUser}
          studyInfoData={{
            physicianId: item?.physicianId,
            patientId: item?.patientId || item?.origPatientId,
            orderId: item?.orderId || item?.origOrderId,
            stageId: item?.stageId || visitsData[0]?.stageId ,
            prefInterpPhysId: item?.prefInterpPhysId,
            studyTypeId: item?.studyTypeId,
            locationId: item?.locationId,
            SDATE: item?.dateOfService1,
            studyIncompleteReason: item?.studyIncompleteReason,
            studyIncomplete: item?.studyIncomplete,
            reasonIncompleteStudyId: item?.reasonIncompleteStudyId,
            studyCompleted: item?.studyCompleted,
            studyId: item?.studyId,
            statusNameId: item?.statusNameId,
            studyCompletedNotes: item?.studyCompletedNotes,
            studyInfoConfirmed: item?.studyInfoConfirmed,
            patientNameVal: `${item?.patientDetails?.lastName}, ${item?.patientDetails?.firstName}`,
            SDATE2: item?.dateOfService2,
            SDATE3: item?.dateOfService3,
            studyTypeId2: item?.studyTypeId2,
            studyCompletedNotes2: item?.studyCompletedNotes2,
            studyTypeCompletedId3: item?.studyTypeCompletedId3,
            studyCompletedNotes3: item?.studyCompletedNotes3,
            apptCompleted1: item?.apptCompleted1,
            apptCompleted2: item?.apptCompleted2,
            apptCompleted3: item?.apptCompleted3,
            deviceReturnDate: item?.deviceReturnDate,
            studyTypeCompletedId: item?.studyTypeCompletedId,
            hstDeviceId: item?.hstDeviceId,
            studyTypeCompletedId2: item?.studyTypeCompletedId2,
            hstDeviceIdNight2: item?.hstDeviceIdNight2,
            hstDeviceIdNight3: item?.hstDeviceIdNight3,
            dob: item?.dob,
            uploadDate: item?.uploadDate,
            deviceDate: item?.deviceDate,
            reasonIncompleteStudyId2: item?.reasonIncompleteStudyId2,
            studyIncomplete2: item?.studyIncomplete2,
            reasonIncompleteStudyId3: item?.reasonIncompleteStudyId3,
            studyIncomplete3: item?.studyIncomplete3,
            dmeOrderId: item?.origDmeOrderId
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
         :
        <UpdateStudyInfoDialog
          notShowPopup={true}
          asDialog={false}
          isPreviousVisits={isPreviousVisits}
          isCurrentVisits={isCurrentVisits}
          reloadCurrentVisit={reloadVisit}
          isWellnecessitiesUser={isWellnecessitiesUser}
          studyInfoData={{
            physicianId: item?.physicianId,
            patientId: item?.patientId || item?.origPatientId,
            orderId: item?.orderId || item?.origOrderId,
            stageId: item?.stageId || visitsData[0]?.stageId ,
            prefInterpPhysId: item?.prefInterpPhysId,
            studyTypeId: item?.studyTypeId,
            locationId: item?.locationId,
            SDATE: item?.dateOfService1,
            studyIncompleteReason: item?.studyIncompleteReason,
            studyIncomplete: item?.studyIncomplete,
            reasonIncompleteStudyId: item?.reasonIncompleteStudyId,
            studyCompleted: item?.studyCompleted,
            studyId: item?.studyId,
            statusNameId: item?.statusNameId,
            studyCompletedNotes: item?.studyCompletedNotes,
            studyInfoConfirmed: item?.studyInfoConfirmed,
            patientNameVal: `${item?.patientDetails?.lastName}, ${item?.patientDetails?.firstName}`,
            SDATE2: item?.dateOfService2,
            SDATE3: item?.dateOfService3,
            studyTypeId2: item?.studyTypeId2,
            studyCompletedNotes2: item?.studyCompletedNotes2,
            studyTypeCompletedId3: item?.studyTypeCompletedId3,
            studyCompletedNotes3: item?.studyCompletedNotes3,
            apptCompleted1: item?.apptCompleted1,
            apptCompleted2: item?.apptCompleted2,
            apptCompleted3: item?.apptCompleted3,
            deviceReturnDate: item?.deviceReturnDate,
            studyTypeCompletedId: item?.studyTypeCompletedId,
            hstDeviceId: item?.hstDeviceId,
            studyTypeCompletedId2: item?.studyTypeCompletedId2,
            hstDeviceIdNight2: item?.hstDeviceIdNight2,
            hstDeviceIdNight3: item?.hstDeviceIdNight3,
            dob: item?.dob,
            uploadDate: item?.uploadDate,
            deviceDate: item?.deviceDate,
            reasonIncompleteStudyId2: item?.reasonIncompleteStudyId2,
            studyIncomplete2: item?.studyIncomplete2,
            reasonIncompleteStudyId3: item?.reasonIncompleteStudyId3,
            studyIncomplete3: item?.studyIncomplete3,
            inLab: item?.inLab,
            multiNight: item?.multiNight
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
        }
        {/* Score Study Info */}
        {
          data?.origDmeOrderId > 0 && !data?.origOrderId && !data?.orderIdOrder ? "":
          <>
            <Box
              sx={{
                border: "1px solid #d0d6d1",
                marginTop: "20px",
              }}
            />
            <ScoreStudyDialog
              notShowPopup={true}
              asDialog={false}
              isPreviousVisits={isPreviousVisits}
              isCurrentVisits={isCurrentVisits}
              reloadCurrentVisit={reloadVisit}
              isWellnecessitiesUser={isWellnecessitiesUser}
              scoreStudyData={{
                physicianId: item?.physicianId,
                patientId: item?.patientId || item?.origPatientId,
                orderId: item?.orderId || item?.origOrderId,
                stageId: item?.stageId,
                prefInterpPhysId: item?.prefInterpPhysId,
                studyTypeId: item?.studyTypeId,
                locationId: item?.locationId,
                SHIP: item?.scoringDate,
                SSIP: item?.sentForInterpDate,
                studyId: item?.studyId,
                scoringTechId: item?.scoringTechId,
                patientNameVal: `${item?.patientDetails?.lastName}, ${item?.patientDetails?.firstName}`,
                scheduleSecondAppt: item?.scheduleSecondAppt,
                dob: item?.dob,
                prelimFileId: item?.prelimFileId,
              }}
              notificationToastSuccess={(message) =>
                dispatch(setSnackbarMessage({ message, type: "success" }))
              }
              notificationToastError={(message) =>
                dispatch(setSnackbarMessage({ message, type: "error" }))
              }
            />
          </>
        }

        {/* Upload Interpretation Info: */}
        {
          data?.origDmeOrderId > 0 && !data?.origOrderId && !data?.orderIdOrder ? "" :
            <>
              <Box
                sx={{
                  border: "1px solid #d0d6d1",
                  marginTop: "20px",
                }}
              />
              <UploadInterpretationDialog
                notShowPopup={true}
                asDialog={false}
                isPreviousVisits={isPreviousVisits}
                isCurrentVisits={isCurrentVisits}
                reloadCurrentVisit={reloadVisit}
                isWellnecessitiesUser={isWellnecessitiesUser}
                interpretationData={{
                  physicianId: item?.physicianId,
                  patientId: item?.patientId,
                  orderId: item?.orderId,
                  surveyId: item?.surveyId,
                  stageId: item?.stageId,
                  prefInterpPhysId: item?.prefInterpPhysId,
                  SDATE: item?.dateOfService1,
                  studyTypeId: item?.studyTypeId,
                  locationId: item?.locationId,
                  SHIP: item?.scoringDate,
                  trackingNum: item?.trackingNum,
                  studyId: item?.studyId,
                  // email: item?.,
                  // cellPhone: item?.,
                  // apptLocationName: item?.,
                  patientNameVal: `${item?.patientDetails?.lastName}, ${item?.patientDetails?.firstName}`,
                  autoCPAP: item?.autoCPAP,
                  dmeOrder: item?.dmeOrder,
                  followupSleepSpecialist: item?.followupSleepSpecialist,
                  data: data,
                  interpFileId: item?.interpFileId,
                  followUpOrder: item?.followUpOrder,
                  scheduleSecondAppt: item?.scheduleSecondAppt,
                  followupPhysicianId: item?.followupPhysicianId,
                  interpretationDate: item?.interpretationDate,
                  followUpInterpretationDate: item?.followUpInterpretationDate,
                  followUpInterpretationNotes: item?.followUpInterpretationNotes
                }}
                notificationToastSuccess={(message) =>
                  dispatch(setSnackbarMessage({ message, type: "success" }))
                }
                notificationToastError={(message) =>
                  dispatch(setSnackbarMessage({ message, type: "error" }))
                }
              />

              {/* InterpretationAvailableDialog */}
              <Box
                sx={{
                  border: "1px solid #d0d6d1",
                  marginTop: "20px",
                }}
              />
              <InterpretationAvailableDialog
                notShowPopup={true}
                asDialog={false}
                isWellnecessitiesUser={isWellnecessitiesUser}
                patientResultInfo={{
                  physicianId: item?.physicianId,
                  patientId: item?.patientId,
                  orderId: item?.orderId,
                  surveyId: item?.surveyId,
                  stageId: item?.stageId,
                  prefInterpPhysId: item?.prefInterpPhysId,
                  SDATE: item?.dateResultDiscussed,
                  locationId: item?.locationId,
                  SHIP: item?.datePatientReferred,
                  studyId: item?.studyId,
                  patientNameVal: `${item?.patientDetails?.lastName}, ${item?.patientDetails?.firstName}`,
                }}
                discussResults={true}
                notificationToastSuccess={(message) =>
                  dispatch(setSnackbarMessage({ message, type: "success" }))
                }
                notificationToastError={(message) =>
                  dispatch(setSnackbarMessage({ message, type: "error" }))
                }
              />
            </>
        }


        
       

        {/* Discuss Results with Patient Info: */}
        {/* <Box
          sx={{
            border: "1px solid #d0d6d1",
            marginTop: "20px",
          }}
        />
        <DiscussResultsDialog
          notShowPopup={true}
          asDialog={false}
          isPreviousVisits={isPreviousVisits}
          reloadCurrentVisit={reloadVisit}
          patientResultInfo={{
            physicianId: item?.physicianId,
            patientId: item?.patientId,
            orderId: item?.orderId,
            surveyId: item?.surveyId,
            stageId: item?.stageId,
            prefInterpPhysId: item?.prefInterpPhysId,
            SDATE: item?.dateResultDiscussed,
            locationId: item?.locationId,
            SHIP: item?.datePatientReferred,
            studyId: item?.studyId,
            patientNameVal: `${item?.patientDetails?.lastName}, ${item?.patientDetails?.firstName}`,
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        /> */}

        {/* Review Interpretation And Select Recommendations For Followup: */}
        {/* <Box
          sx={{
            border: "1px solid #d0d6d1",
            marginTop: "20px",
          }}
        />
        <ResultsFollowupDialog
          notShowPopup={true}
          asDialog={false}
          isPreviousVisits={isPreviousVisits}
          isCurrentVisits={isCurrentVisits}
          resultFollowupData={{
            resultsFollowup: item?.resultsFollowup,
            orderOtherTreatment: item?.orderOtherTreatment,
            patientId: item?.patientId,
            orderId: item?.orderId,
            surveyId: item?.surveyId,
            stageId: item?.stageId,
            prefInterpPhysId: item?.prefInterpPhysId,
            locationId: item?.locationId,
            studyId: item?.studyId,
            patientNameVal: `${item?.patientDetails?.lastName}, ${item?.patientDetails?.firstName}`,
            orderDetails: visitsData?.orderDetails,
          }}
          notificationToastSuccess={(message) =>
            dispatch(
              setSnackbarMessage({ message, type: "success" })
            )
          }
          notificationToastError={(message) =>
            dispatch(
              setSnackbarMessage({ message, type: "error" })
            )
          }
        /> */}

        {/* Treatment Appointment Info: */}
        {/* {console.log("item=>=>", item)}
                                    <Box sx={{ border: "1px solid #d0d6d1", marginTop: "20px" }} />
                                    <TreatmentAppointmentDialog
                                        asDialog={false}
                                        isPreviousVisits={isPreviousVisits}
                                        treatmentAppoinmentData = {
                                            {
                                                patientId: item?.patientId,
                                                orderId: item?.orderId,
                                                stageId: item?.stageId,
                                                prefInterpPhysId: item?.prefInterpPhysId,
                                                SDATE: item?.studyCompletedDate,
                                                completedTreatmentAppt: item?.completedTreatmentAppt,
                                                canceledTreatmentAppt: item?.canceledTreatmentAppt,
                                                incompleteTreatmentAppt: item?.incompleteTreatmentAppt,
                                                techNotes: item?.techNotes,
                                                incompleteTreatmentApptDetails: item?.incompleteTreatmentApptDetails,
                                                reasonCancelingTreatmentAppt: item?.reasonCancelingTreatmentAppt,
                                                physicianId: item?.physicianId,
                                                locationId: item?.locationId,
                                                studyId: item?.studyId,
                                                reasonRescheduling: item?.reasonRescheduling,
                                                dmeOrderId: item?.dmeOrderId
                                            }
                                        }
                                        notificationToastSuccess={(message) =>
                                            dispatch(setSnackbarMessage({ message, type: "success" }))
                                        }
                                        notificationToastError={(message) =>
                                            dispatch(setSnackbarMessage({ message, type: "error" }))
                                        }
                                    /> */}
      </>
    );
  };


  return (
    <>
      {data?.length !== 0 &&
        data?.map((item, index) => {
          return (
            <Accordion
              expanded={expanded === index}
              onChange={handleChange(index)}
              className="visits-accordian"
              key={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                data-testid="accordion-summary"
              >
                <Grid container>
                  <Grid item lg={4} md={4} xs={12}>
                    {(item?.origOrderId || item?.orderDate || item?.origDmeOrderId > 0 ) ? (
                      <>
                        <Typography variant="strong">Order : </Typography>
                        <Typography variant="span" data-testid="order-Date">
                          {item?.orderDate && dateConverter(item?.orderDate)}
                        </Typography>
                      </>
                    ) : (
                      item?.surveyDate && (
                        <>
                          <Typography variant="strong">Survey : </Typography>
                          <Typography variant="span" data-testid="survey-Date">
                            {item?.surveyDate &&
                              dateConverter(item?.surveyDate)}
                          </Typography>
                        </>
                      )
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <Typography variant="strong">Current Stage : </Typography>
                    <Typography variant="span">{item?.currentStage}</Typography>
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <Typography variant="strong">Current Status : </Typography>
                    <Typography variant="span">
                      {item?.currentStatus}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails data-testid="accordion-summary-content">
                <Grid container rowSpacing={{ md: 3 }}>
                  <Grid item lg={12} md={12}>
                    {/* survey and order popup */}
                    <SurveyAndOrder item={item} />

                    <Box
                      sx={{ border: "1px solid #d0d6d1", margin: "20px 0px" }}
                    />
                    <Grid container className={classes.borderBoxShadow}>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* stages table */}
                        <StageTable stages={item?.stages} />
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        {/* Stage pie chart */}
                        <CustomStagesPieChart stages={item?.stages} />
                      </Grid>
                    </Grid>

                    {/* waiting to schedule info */}
          {/*         {(item?.origOrderId && item?.orderDate) || (item?.origDmeOrderId > 0 && !item?.origOrderId && !item?.orderIdOrder) && ( */}
            { (item?.origOrderId || item?.orderDate || item?.origDmeOrderId > 0 )&& (
                      <>
                        <Box
                          sx={{
                            border: "1px solid #d0d6d1",
                            marginTop: "20px",
                          }}
                        />
                        <Box>
                          <WaitingScheduleDialog
                            notShowPopup={true}
                            asDialog={false}
                            isPreviousVisits={isPreviousVisits}
                            reloadCurrentVisit={reloadVisit}
                            isWellnecessitiesUser={isWellnecessitiesUser}
                            waitingScheduleData={{
                              stageId: item?.stageId,

                              contacAttempt: orderData?.contacAttempt || dmeOrderData?.contacAttempt || item?.studyData?.contacAttempt,
                              dmeOrderId: orderData?.dmeOrderId || dmeOrderData?.dmeOrderId,
                              orderId: orderData?.orderId || dmeOrderData?.orderId,
                              patUnreachable: orderData?.patUnreachable || dmeOrderData?.patUnreachable || item?.studyData?.patUnreachable ,
                              contactLaterDate: orderData?.studyDeclined || orderData?.contactLaterDate || dmeOrderData?.contactLaterDate || item?.studyData?.contactLaterDate ,
                              studyDeclined: dmeOrderData?.studyDeclined || item?.studyData?.studyDeclined,
                              SDATE: orderData?.contactLaterDate || dmeOrderData?.contactLaterDate || item?.studyData?.contactLaterDate ,
                              waitingScheduleNotes: orderData?.waitingScheduleNotes || dmeOrderData?.waitingScheduleNotes || item?.studyData?.waitingScheduleNotes,
                              reasonDecliningId: orderData?.reasonDecliningId || dmeOrderData?.reasonDecliningId || item?.studyData?.reasonDecliningId,
                              patientId: orderData?.patientId || dmeOrderData?.patientId
                            }}
                            notificationToastSuccess={(message) =>
                              dispatch(
                                setSnackbarMessage({ message, type: "success" })
                              )
                            }
                            notificationToastError={(message) =>
                              dispatch(
                                setSnackbarMessage({ message, type: "error" })
                              )
                            }
                          />
                        </Box>
                      </>
                    )}

                    {item?.origDmeOrderId > 0 && !item?.origOrderId && !item?.orderIdOrder && item.studyData &&
                      <>
                        
                      <Box sx={{ border: "1px solid #d0d6d1", marginTop: "20px" }} />
                      <TreatmentScheduleStudyDialog
                        asDialog={false}
                        isPreviousVisits={isPreviousVisits}
                        treatmentScheduleStudyData={
                          {
                         //   physicianId: item?.physicianIdStudy,       
                           // surveyId: item?.surveyIdSurvey,
                           // orderId: item?.orderIdOrder,
                            stageId: item?.stageId,
                            //prefInterpPhysId: item?.prefInterpPhysId,
                           // time: item?.time,
                            dmeOrderId: item?.dmeOrderId,
                            studyId: item?.studyData?.studyId,
                            physicianId: item?.studyData?.physicianId,
                            patientId: item?.patientId || item?.origPatientId,
                            surveyId: item?.studyData?.surveyId,
                            orderId: item?.orderId || item?.origOrderId,
                            studyTypeId: item?.studyData?.studyTypeId,
                            orderingPhysicianName: item?.studyData?.orderingPhysicianName,
                            prefInterpPhysId: item?.studyData?.prefInterpPhysId,
                            studyDate: item?.studyData?.date,
                            shipDate: item?.studyData?.shipDate,
                            trackingNum: item?.studyData?.trackingNum,
                            outTrackingNum: item?.studyData?.outTrackingNum,
                            locationId: item?.studyData?.locationId,
                            time: item?.studyData?.time,
                            deviceReturnDate: item?.studyData?.deviceReturnDate,
                            hstDeviceId: item?.studyData?.hstDeviceId,
                            pickup:item?.studyData?.pickup,
                            hstSerialNumber:item?.studyData?.hstSerialNumber,
                            dmeBrandId: item?.studyData?.dmeBrandId,
                            dmeModelId: item?.studyData?.dmeModelId,
                            SDATE:item?.studyData?.date
                          }
                        }
                        notificationToastSuccess={(message) =>
                          dispatch(setSnackbarMessage({ message, type: "success" }))
                        }
                        notificationToastError={(message) =>
                          dispatch(setSnackbarMessage({ message, type: "error" }))
                        }
                      />

                      {/* <Box sx={{ border: "1px solid #d0d6d1", marginTop: "20px" }} />
                      <TreatmentAppointmentDialog
                        asDialog={false}
                        isPreviousVisits={isPreviousVisits}
                        treatmentAppoinmentData={
                          {
                            patientId: item?.patientId,
                            orderId: item?.orderId,
                            stageId: item?.stageId,
                            prefInterpPhysId: item?.prefInterpPhysId,
                            SDATE: item?.studyCompletedDate,
                            completedTreatmentAppt: item?.completedTreatmentAppt,
                            canceledTreatmentAppt: item?.canceledTreatmentAppt,
                            incompleteTreatmentAppt: item?.incompleteTreatmentAppt,
                            techNotes: item?.techNotes,
                            incompleteTreatmentApptDetails: item?.incompleteTreatmentApptDetails,
                            reasonCancelingTreatmentAppt: item?.reasonCancelingTreatmentAppt,
                            physicianId: item?.physicianId,
                            locationId: item?.locationId,
                            studyId: item?.studyId,
                            reasonRescheduling: item?.reasonRescheduling,
                            dmeOrderId: item?.dmeOrderId
                          }
                        }
                        notificationToastSuccess={(message) =>
                          dispatch(setSnackbarMessage({ message, type: "success" }))
                        }
                        notificationToastError={(message) =>
                          dispatch(setSnackbarMessage({ message, type: "error" }))
                        }
                      /> */}
                      </>
                    }

                    {item.hasOwnProperty("studyData") ? isPreviousVisits ? prevExpanded ?   handleStudyComponent(item.studyData, item.stageId, item) : "":

                      handleStudyComponent(item.studyData, item.stageId, item) : ""}

                      {/* invalid referral followup */}
                    {
                      item?.stageNameId === 2 && item?.currentStatus === "Uploaded" ?
                        <>
                          <Box
                            sx={{
                              border: "1px solid #d0d6d1",
                              marginTop: "20px",
                            }}
                          />
                          <OrderInfoDialog
                            notShowPopup={true}
                            asDialog={false}
                            isWellnecessitiesUser={isWellnecessitiesUser}
                            orderInfoData={{
                              patientId: item?.patientId || item?.origPatientId,
                              orderId: item?.orderId || item?.origOrderId,
                              stageId: item?.stageId,
                              bestPhone: item?.bestPhone,
                              cellPhone: item?.cellPhone,
                              E66_2: item?.E66_2,
                              F51_12: item?.F51_12,
                              F51_3: item?.F51_3,
                              F51_4: item?.F51_4,
                              F51_5: item?.F51_5,
                              G47_11: item?.G47_11,
                              G47_12: item?.G47_12,
                              G47_13: item?.G47_13,
                              G47_19: item?.G47_19,
                              G47_30: item?.G47_30,
                              G47_31: item?.G47_31,
                              G47_33: item?.G47_33,
                              G47_411: item?.G47_411,
                              G47_419: item?.G47_419,
                              G47_421: item?.G47_421,
                              G47_421_wo_cataplexy: item?.G47_421_wo_cataplexy,
                              G47_61: item?.G47_61,
                              G47_69: item?.G47_69,
                              G47_9: item?.G47_9,
                              physicianId: item?.physicianIdStudy,
                              prefInterpPhysId: item?.prefInterpPhysId,
                              placeOfService: item?.placeOfService,
                              snoring: item?.snoring,
                              daytimeSleepiness: item?.daytimeSleepiness,
                              apneas: item?.apneas,
                              gasping: item?.gasping,
                              inappropriateSleep: item?.inappropriateSleep,
                              verbiage: item?.verbiage,
                              validity: item?.validity,
                              name: item?.name,
                              orderingPhysicianPhone: item?.orderingPhysicianPhone,
                              orderingPhysicianMobileNum: item?.orderingPhysicianMobileNum,
                              addlInstructions: item?.addlInstructions
                            }}
                            discussResults={true}
                            notificationToastSuccess={(message) =>
                              dispatch(setSnackbarMessage({ message, type: "success" }))
                            }
                            notificationToastError={(message) =>
                              dispatch(setSnackbarMessage({ message, type: "error" }))
                            }
                          />
                        </> : ""
                    }

                    <Box
                      sx={{ border: "1px solid #d0d6d1", margin: "20px 0px" }}
                    />
                  {item?.orderInfo === 1 &&
                    <Box>
                      <FormTable patientIdForm={item?.origPatientId} orderId={item?.origOrderId}  consentForm={true}/>
                    </Box>
                   }

                  { expanded === 0 && <Box>
                      <VisitsFile item={item} />
                    </Box>}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
}

VisitsAccordian.useAxios = useAxios;
export default VisitsAccordian;
