import { useEffect, useState } from "react";
import * as React from "react";
import { useAuth } from "../../../hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { getRefreshPreviligeData, patientsPaginationGetData, pulldownsPostData } from "../../../axios/api";
import { updateSelectedPatient } from "../../../store/slices/selectedPatientSlice";
import { Download, Delete, Visibility } from "@mui/icons-material";
import dayjs from "dayjs";
import {
  Button,
  Container,
  Alert,
  Snackbar,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "../../../../node_modules/@mui/material/index";
import { useAxios } from "../../../hooks/auth";
import PatientInfo from "./PatientInfo";
import InsuranceInfo from "./InsuranceInfo";
import GroupPulldown from "../../../components/misc/GroupPulldown";
import phoneNumberValidator from "../../../utils/phoneNumberValidator";
import FileUpload from "./FileUpload/FileUpload";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../../utils/notification";
import { ToastContainer } from "react-toastify";
import {
  collapsedDrawerWidth,
  openDrawerWidth,
} from "../../../constants/sideBarwidth";
import { patientViewGetData, patientsPostData } from "../../../axios/api";
import classes from "./patientView.module.css";
import TabPanel from "../../../components/patientsComponent/TabPanel";
import CurrentVisits from "../../../components/patientsComponent/currentVisits/CurrentVisits";
import PriviousVisits from "../../../components/patientsComponent/priviousVisits/PriviousVisits";
import AgeCalculator from "../../../components/patientsComponent/AgeCalculator";
import MinorPatients from "../../../components/patientsComponent/minorPatient/MinorPatients";
import CCInformation from "../../../components/patientsComponent/CCInformation/CCInformation";
import { validateEmail } from "../../../utils/emailValidator";
import { Buffer } from "buffer";
import FileView from "./FileUpload/FileView";
import PatientContactLog from "./PatientContactLog";
import UpdatePassword from "../../../components/profileComponent/UpdatePassword";
import { base64ToPdf } from "../../../utils/base64ToPDFConvertor";
import OrderInfo from "./OrderInfo";
import { isChooseISDDiagnosis } from "../../../utils/signOrderFnc";
import useIsPrevileges from "../../../hooks/useRoleAndPrivileges";
import useIsGroupId from "../../../hooks/groupIdInfo";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useIsUserId from "../../../hooks/userIdInfo";

function PatientView({ isPatientPortal = false }) {
  const { patientReduxData } = useSelector(
    (state) => state?.patientLogInDataSelector
  );
  const [viewCarrierState, setViewCarrierState] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:800px)");
  const axios = useAxios();
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({});
  const [rowStateData, setRowstateData] = useState("");
  const [patientsPhoneNumber, setPatientsPhoneNumber] = useState({
    bestPhone: "",
    cellPhone: "",
    parentPhoneNumber: "",
    emerContactNum: "",
    bestPhoneError: false,
    cellPhoneError: false,
    parentPhoneNumberError: false,
    emerContactNumError: false,
  });

  // const [Data, setPhpData] = useState("");
  const [rowData, setRowData] = useState([]);
  const [rowContactLog, setRowContactLog] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [pdfURLState, setPdfURLState] = useState({ fileUrl: "", fileType: "" });
  const { isCollapsed } = useSelector((state) => state.sideBarCollapseSelector);
  const [formErrorState, setFormErrorState] = useState({
    firstName: "",
    lastName: "",
    groupId: "",
    cellPhone: "",
    emerContactName: "",
    emerContactNum:""
  });
  const [ssnError, setSsnError] = useState(false)

  const [emailRequired, setEmailRequired] = useState({
    isEmailRequired: false,
    isvalidEmail: true,
  });
  const [DOB, setDOB] = React.useState(null);
  const [errorDOB, setErrorDOB] = React.useState(false);
  const [patientAlredyExist, setPatientAlredyExist] = useState({});
  const [BMI, setBMIState] = useState({});
  const [inputError, setInputError] = useState(false);
  const [expDateError, setExpDateError] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const [locations, setLocations] = useState([]);
  const [patientId, setPatientId] = useState("");
  const [expirationDate, setExpirationDate] = useState(null);
  const [errorexpirationDate, setErrorExpirationDate] = useState(false);
  const { roleId,id, userInfo, isEncryptedInfo } = useIsPrevileges();
  const userGroupId = useIsGroupId();
  const [isDisabled, setIsDisabled] = useState(false)
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [previleges,setPrevileges] = useState('')
  const [submitEnabledCardNum, setSubmitEnabledCardNum] = useState(false);
  const [submitEnabledCVC, setSubmitEnabledCVC] = useState(false);
  const [submitEnabledSSN, setSubmitEnabledSSN] = useState(false);
  const [tabData,setTabData] = useState(false)

  React.useEffect(() => {
    if (location?.state) {
      const { row, patientInfo, searchPatientInfo, nav } = location?.state;
      setRowstateData(row | patientInfo);
      refreshDataForPriviliege();
      if (row) {
        const { years, months } = calculate_age(row[4]);
        const {
          formattedPhoneNumber: bestPhone,
          numericPhoneNumber: bestPhoneNumeric,
        } = phoneNumberValidator(row[14]);
        const {
          formattedPhoneNumber: emerContactNum,
          numericPhoneNumber: emerContactNumNumeric,
        } = phoneNumberValidator(row[47]);
        const {
          formattedPhoneNumber: cellPhone,
          numericPhoneNumber: cellPhoneNumeric,
        } = phoneNumberValidator(row[15]);
        const {
          formattedPhoneNumber: parentMobile,
          numericPhoneNumber: parentMobileNumeric,
        } = phoneNumberValidator(row[27]);
        setPatientsPhoneNumber({
          ...patientsPhoneNumber,
          bestPhone: bestPhoneNumeric,
          cellPhone: cellPhoneNumeric,
          parentPhoneNumber: parentMobileNumeric,
          emerContactNum: emerContactNumNumeric,
        });
        setFormState({
          ...formState,
          patientNum: row[1],
          lastName: row[2],
          firstName: row[3],
          dob: row[4],
          middleInitial: row[23],
          gender: row[5],
          address1: row["8"],
          address2: row["9"],
          city: row["10"],
          state: row["11"],
          zip: row[12],
          email: row[13],
          bestPhone: bestPhone,
          cellPhone: cellPhone,
          primaryInsuranceId: row[16],
          secondaryInsuranceId: row[17],
          primaryPolicy: row[18],
          primaryGroup: row[19],
          secondaryPolicy: row[20],
          secondaryGroup: row[21],
          groupId: row[22],
          groupName: row[6],
          enablePortal: row[24],
          patientId: row[0],
          parentPhoneNumber: parentMobile,
          parentLastName: row[25],
          parentFirstName: row[26],
          primaryInsuranceName: row[28],
          secondaryInsuranceName: row[29],
          age: row[30] ? row[30] : years,
          months: row[57] ? row[57] : months,
          race: row[31],
          heightFeet: row[32],
          heightInches: row[33],
          patWeight: row[34],
          patBMI: row[35],
          maritalStatus: row[36],
          prefLanguage: row[37],
          ssn: row[38],
          placeofemp: row[39],
          delAddress1: row[40],
          delAddress2: row[41],
          delCity: row[42],
          delState: row[43],
          delZip: row[44],
          emergencyContact: row[45],
          emerContactName: row[46],
          emerContactNum: emerContactNum,
          tertiaryInsuranceId: row[48],
          tertiaryPolicy: row[49],
          tertiaryGroup: row[50],
          heartDisease: row[51],
          disabilities: row[52],
          specialAccomodation: row[53],
          careGiver: row[54],
          careGiverName: row[55],
          parentEmail: row[56],
        });
      
        if (row[4]) setDOB(dayjs(row[4]));
      } else if (patientInfo) {
        const { years, months } = calculate_age(patientInfo?.dob);
    
        const {
          formattedPhoneNumber: bestPhone,
          numericPhoneNumber: bestPhoneNumeric,
        } = phoneNumberValidator(patientInfo?.bestPhone);
        const {
          formattedPhoneNumber: emerContactNum,
          numericPhoneNumber: emerContactNumNumeric,
        } = phoneNumberValidator(patientInfo?.emerContactNum);
        const {
          formattedPhoneNumber: cellPhone,
          numericPhoneNumber: cellPhoneNumeric,
        } = phoneNumberValidator(patientInfo?.cellPhone);
        const {
          formattedPhoneNumber: parentMobile,
          numericPhoneNumber: parentMobileNumeric,
        } = phoneNumberValidator(patientInfo?.parentMobile);

        setPatientsPhoneNumber({
          ...patientsPhoneNumber,
          bestPhone: bestPhoneNumeric,
          cellPhone: cellPhoneNumeric,
          parentPhoneNumber: parentMobileNumeric,
          emerContactNum: emerContactNumNumeric,
        });
        setFormState({
          ...patientInfo,
          bestPhone,
          cellPhone,
          emerContactNum,
          parentPhoneNumber: parentMobile,
          groupId: patientInfo?.groupId || patientInfo?.patientGroupId,
          age: years,
          months: months,
          physicianId:patientInfo?.physicianIdOrder
        });
        setBMIState({
          ...BMI,
          heightFeet: patientInfo?.heightFeet,
          heightInches: patientInfo?.heightInches,
          patBMI: patientInfo?.patBMI,
          patWeight: patientInfo?.patWeight,
        });
        if (patientInfo?.dob) setDOB(dayjs(patientInfo?.dob));
        let selectedStudyType = "";
        if (patientInfo["2nightProtocol"]) {
          selectedStudyType = "2nightProtocol";
        } else if (patientInfo["splitNightProtocol"]) {
          selectedStudyType = "splitNightProtocol";
        } else if (patientInfo["psgBaselineStudy"]) {
          selectedStudyType = "psgBaselineStudy";
        } else if (patientInfo["homeSleepTest"]) {
          selectedStudyType = "homeSleepTest";
        } else if (patientInfo["cpapTitration"]) {
          selectedStudyType = "cpapTitration";
        } else if (patientInfo["bipapTitration"]) {
          selectedStudyType = "bipapTitration";
        } else if (patientInfo["bipapStTitration"]) {
          selectedStudyType = "bipapStTitration";
        } else if (patientInfo["AsvTitration"]) {
          selectedStudyType = "AsvTitration";
        } else if (patientInfo["AvapsTitration"]) {
          selectedStudyType = "AvapsTitration";
        } else if (patientInfo["pediatricSleepStudy"]) {
          selectedStudyType = "pediatricSleepStudy";
        }
        if (selectedStudyType) {
          setSelectedCheckbox(selectedStudyType);
        }
      } else if (searchPatientInfo) {
        setRowstateData("");
        setFormState({
          ...searchPatientInfo,
          enablePortal: Boolean(nav === "addPatient"),
        });
        if (searchPatientInfo?.dob) setDOB(dayjs(searchPatientInfo?.dob));
      }
    }
    if (isPatientPortal) {
      const { years, months } = calculate_age(patientReduxData?.dob);
      const {
        formattedPhoneNumber: bestPhone,
        numericPhoneNumber: bestPhoneNumeric,
      } = phoneNumberValidator(patientReduxData?.bestPhone);
      const {
        formattedPhoneNumber: emerContactNum,
        numericPhoneNumber: emerContactNumNumeric,
      } = phoneNumberValidator(patientReduxData?.emerContactNum);
      const {
        formattedPhoneNumber: cellPhone,
        numericPhoneNumber: cellPhoneNumeric,
      } = phoneNumberValidator(patientReduxData?.cellPhone);
      const {
        formattedPhoneNumber: parentMobile,
        numericPhoneNumber: parentMobileNumeric,
      } = phoneNumberValidator(patientReduxData?.parentMobile);
      setFormState({
        ...formState,
        ...patientReduxData,
        bestPhone,
        cellPhone,
        emerContactNum,
        parentPhoneNumber: parentMobile,
        age: years,
        months: months
      });
      setPatientsPhoneNumber({
        ...patientsPhoneNumber,
        bestPhone: bestPhoneNumeric,
        cellPhone: cellPhoneNumeric,
        emerContactNum: emerContactNumNumeric,
        parentPhoneNumber: parentMobileNumeric,
      });
      setBMIState({
        ...BMI,
        heightFeet: patientReduxData?.heightFeet,
        heightInches: patientReduxData?.heightInches,
        patBMI: patientReduxData?.patBMI,
        patWeight: patientReduxData?.patWeight,
      });
      if (patientReduxData?.dob) setDOB(dayjs(patientReduxData?.dob));
      patientViewGetData(axios, "viewPatient", id)
      .then((res) => {
        if (res?.length) {
          let patientRecord = res[0]
          const { years, months } = calculate_age(patientRecord?.dob);
          const {
            formattedPhoneNumber: bestPhone,
            numericPhoneNumber: bestPhoneNumeric,
          } = phoneNumberValidator(patientRecord?.bestPhone);
          const {
            formattedPhoneNumber: emerContactNum,
            numericPhoneNumber: emerContactNumNumeric,
          } = phoneNumberValidator(patientRecord?.emerContactNum);
          const {
            formattedPhoneNumber: cellPhone,
            numericPhoneNumber: cellPhoneNumeric,
          } = phoneNumberValidator(patientRecord?.cellPhone);
          const {
            formattedPhoneNumber: parentMobile,
            numericPhoneNumber: parentMobileNumeric,
          } = phoneNumberValidator(patientRecord?.parentMobile);
          setFormState({
            ...formState,
            ...patientRecord,
            bestPhone,
            cellPhone,
            emerContactNum,
            parentPhoneNumber: parentMobile,
            age: years,
            months: months
          });    
          setPatientsPhoneNumber({
            ...patientsPhoneNumber,
            bestPhone: bestPhoneNumeric,
            cellPhone: cellPhoneNumeric,
            emerContactNum: emerContactNumNumeric,
            parentPhoneNumber: parentMobileNumeric,
          });
          setBMIState({
            ...BMI,
            heightFeet: patientRecord?.heightFeet,
            heightInches: patientRecord?.heightInches,
            patBMI: patientRecord?.patBMI,
            patWeight: patientRecord?.patWeight,
          });
        }
      }).catch((err) => {
        console.log("err==>", err);
      })
    }
  }, [location, patientReduxData,tabData]);
  const { cookies } = useAuth();
  const authToken = cookies["token"];
  const userId = useIsUserId();

  useEffect(() => {
    if (location.state || patientReduxData?.patientId) {
      async function setDataFirstTime() {
        let patientId;
        if (location?.state?.patientInfo) {
          const { patientInfo } = location?.state;
          patientId = patientInfo ? patientInfo?.patientId : undefined;
        } else if (location?.state?.row) {
          const { row } = location?.state;
          patientId = row ? row[0] : undefined;
        } else if (patientReduxData) {
          patientId = patientReduxData
            ? patientReduxData?.patientId
            : undefined;
        }
        refreshDataForPriviliege();
        // let data;
        // const { patientInfo } = location?.state;
        // const patientId = patientInfo ? patientInfo.patientId : undefined;
        // var id = 0;
        // if (patientId === undefined) {
        //   id = location.state.row[0] ? location.state.row[0] : undefined;
        // } else {
        //   id = patientId;
        // }

        // if (!id) return;
        if (!patientId) return;
        patientViewGetData(axios, "listFiles", patientId)
          .then((res) => {
            if (res) {
              // setPhpData(res);
              setRowData(res);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });

        patientViewGetData(axios, "getContactLog", patientId)
          .then((res) => {
            if (res) {
              setRowContactLog(res);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
      setDataFirstTime();
      if(formState.patientId){
        ScheduleLocation({ patientId: formState.patientId })
      }
    }
  }, [refreshData, location, patientReduxData,tabData]);

  const patientIdNew = localStorage.getItem('patientId') || patientId;

  useEffect(() => {
    if(patientIdNew){
      const paramsName = "patientView";
      const payload = {
        patientId: patientIdNew,
      };
      refreshDataForPriviliege();
      patientsPaginationGetData(axios, paramsName, payload)
      .then((res) => {
        const patientDetails = res[0];
        setPatientId(patientDetails?.patientId)
        const { years, months } = calculate_age(patientDetails?.dob);
        const {
          formattedPhoneNumber: bestPhone,
          numericPhoneNumber: bestPhoneNumeric,
        } = phoneNumberValidator(patientDetails?.bestPhone);
        const {
          formattedPhoneNumber: emerContactNum,
          numericPhoneNumber: emerContactNumNumeric,
        } = phoneNumberValidator(patientDetails?.emerContactNum);
        const {
          formattedPhoneNumber: cellPhone,
          numericPhoneNumber: cellPhoneNumeric,
        } = phoneNumberValidator(patientDetails?.cellPhone);
        const {
          formattedPhoneNumber: parentMobile,
          numericPhoneNumber: parentMobileNumeric,
        } = phoneNumberValidator(patientDetails?.parentMobile);

        setPatientsPhoneNumber({
          ...patientsPhoneNumber,
          bestPhone: bestPhoneNumeric,
          cellPhone: cellPhoneNumeric,
          parentPhoneNumber: parentMobileNumeric,
          emerContactNum: emerContactNumNumeric,
        });
        setFormState({
          ...patientDetails,
          bestPhone,
          cellPhone,
          emerContactNum,
          parentPhoneNumber: parentMobile,
          groupId: patientDetails?.groupId || patientDetails?.patientGroupId,
          age:years,
          months:months,
        });
        setBMIState({
          ...BMI,
          heightFeet: patientDetails?.heightFeet,
          heightInches: patientDetails?.heightInches,
          patBMI: patientDetails?.patBMI,
          patWeight: patientDetails?.patWeight,
        });
        if (patientDetails?.dob) setDOB(dayjs(patientDetails?.dob));
        let selectedStudyType = "";
        if (patientDetails["2nightProtocol"]) {
          selectedStudyType = "2nightProtocol";
        } else if (patientDetails["splitNightProtocol"]) {
          selectedStudyType = "splitNightProtocol";
        } else if (patientDetails["psgBaselineStudy"]) {
          selectedStudyType = "psgBaselineStudy";
        } else if (patientDetails["homeSleepTest"]) {
          selectedStudyType = "homeSleepTest";
        } else if (patientDetails["cpapTitration"]) {
          selectedStudyType = "cpapTitration";
        } else if (patientDetails["bipapTitration"]) {
          selectedStudyType = "bipapTitration";
        } else if (patientDetails["bipapStTitration"]) {
          selectedStudyType = "bipapStTitration";
        } else if (patientDetails["AsvTitration"]) {
          selectedStudyType = "AsvTitration";
        } else if (patientDetails["AvapsTitration"]) {
          selectedStudyType = "AvapsTitration";
        } else if (patientDetails["pediatricSleepStudy"]) {
          selectedStudyType = "pediatricSleepStudy";
        }
        if (selectedStudyType) {
          setSelectedCheckbox(selectedStudyType);
        }
      })
      .catch((err) => notificationToastError(err?.message));

      patientViewGetData(axios, "listFiles", patientIdNew)
          .then((res) => {
            if (res) {
              // setPhpData(res);
              setRowData(res);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });

        patientViewGetData(axios, "getContactLog", patientIdNew)
          .then((res) => {
            if (res) {
              setRowContactLog(res);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
    }
    
  }, [patientIdNew,tabData])
  

  useEffect(()=>{
    if(formState?.heightFeet || formState?.heightInches || formState?.patWeight || formState?.patBMI){
      setBMIState({
        ...BMI,
        heightFeet: formState?.heightFeet || '',
        heightInches: formState?.heightInches || '',
        patWeight: formState?.patWeight || '',
        patBMI: formState?.patBMI || ''
      })
    }
    
  },[])

  const refreshDataForPriviliege = () =>{
    getRefreshPreviligeData(axios, { userId: userId })
        .then((res) => {
          setPrevileges(res?.userInfo?.priveleges)
        })
        .catch((err) => {
          console.log("err", err);
        });
  }

  function ScheduleLocation(payload) {
    pulldownsPostData(axios, "scheduleLocationPulldown", payload)
      .then((res) => {
        if (res && res.data) setLocations(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // console.log("patientsPhoneNumber==>",patientsPhoneNumber, "formState==>",formState);
  const download = (id, tableName, idColumn, isView) => {
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    };
    patientsPostData(axios, "downloadFile", payload)
      .then((res) => {
        if (res.data.file) {
          if (isView === "view") {
            setOpenPdfModal(true);
            let base64 = res?.data?.file;
            base64 = base64?.split(",")[1];
            let fileType = res?.data?.name;
            fileType = fileType?.split(".")?.pop();
            const pdfUrl = base64ToPdf(base64, fileType);
            setPdfURLState({
              fileUrl: pdfUrl,
              fileType,
            });
          } else {
            notificationToastSuccess("File is downloading.");
            const downloadLink = document.createElement("a");
            downloadLink.href = res.data.file;
            downloadLink.download = res.data.name;
            downloadLink.click();
          }
        } else {
          notificationToastError("File was not downloaded due to issue.");
        }
        // setRefreshData(!refreshData);
      })
      .catch((err) => notificationToastError(err?.message));
  };

  const deleteRecord = (id, tableName, idColumn) => {
    const yesToDelete = window.confirm("Are you sure you want to delete it? ");
    if (!yesToDelete) return;
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    };
    patientsPostData(axios, "deleteRecord", payload)
      .then((res) => {
        if (res.data.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
        setRefreshData(!refreshData);
      })
      .catch((err) => notificationToastError(err?.message));
  };
  const handlePatientView = () => {
    const paramsName = "patientView";
    const payload = {
      patientId: patientAlredyExist?.patientId,
    };
    setPatientAlredyExist({
      isAlredyExist: false,
    });

    patientsPaginationGetData(axios, paramsName, payload)
      .then((res) => {
        setRowData(res);
        if (res?.length) {
          const dataObj = res[0];
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          if (dataObj?.dob) setDOB(dayjs(dataObj?.dob));
          setRowstateData(0);
          const {
            formattedPhoneNumber: bestPhone,
            numericPhoneNumber: bestPhoneNumeric,
          } = phoneNumberValidator(dataObj?.bestPhone);
          const {
            formattedPhoneNumber: emerContactNum,
            numericPhoneNumber: emerContactNumNumeric,
          } = phoneNumberValidator(dataObj?.emerContactNum);

          const {
            formattedPhoneNumber: cellPhone,
            numericPhoneNumber: cellPhoneNumeric,
          } = phoneNumberValidator(dataObj?.cellPhone);
          setFormState({ ...dataObj, cellPhone, bestPhone, emerContactNum });
          setPatientsPhoneNumber({
            ...patientsPhoneNumber,
            bestPhone: bestPhoneNumeric,
            cellPhone: cellPhoneNumeric,
            emerContactNum: emerContactNumNumeric,
          });
        }
      })
      .catch((err) => notificationToastError(err?.message));
  };

  const updateCheckBox = (e) => {
    if (e.target.id === "enablePortal" && !e.target.checked) {
      setEmailRequired({
        ...emailRequired,
        isEmailRequired: false,
      });
    }
    if (e.target.id === "minorPatient") {
      setFormState({
        ...formState,
        emergencyContact: e.target.checked ? 0 : 1,
        minorPatient: Number(e.target.checked),
      });
    } else if (e.target.id === "emergencyContact") {
      setFormState({
        ...formState,
        // minorPatient: e.target.checked ? 0 : 1,
        emergencyContact: Number(e.target.checked),
      });
    } else {
      setFormState({
        ...formState,
        [e.target.id || e.target.name]: Number(e.target.checked),
      });
    }
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };

  const selectedPracticeId = useSelector(
    (state) => state.practiceSelector.value
  );

  const updateInput = (e) => {
    if (patientAlredyExist?.isAlredyExist) {
      setPatientAlredyExist({});
    }
    if (emailRequired?.isEmailRequired || !emailRequired?.isvalidEmail) {
      setEmailRequired({
        ...emailRequired,
        isEmailRequired: false,
        isvalidEmail: true,
      });
    }
    if(e.target.name === "physicianId"){
      pulldownsPostData(axios, "prefInterpPhysician", {
        physicianId: e.target.value
      })
        .then((res) => {
          if(res.data){
            setFormState({
              ...formState,
              [e.target.id || e.target.name]: e.target.value,
              prefInterpPhysId: res?.data[0]?.userId,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }else{
      setFormState({
        ...formState,
        [e.target.id || e.target.name]: e.target.value,
      });
    }
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    if (e.target.id || e.target.name === "prefInterpPhysId" || e.target.name === "physicianId") {
      let payload = {
        physicianId: e.target.name === "physicianId" ? e.target.value : formState?.physicianId,
        patientId: formState?.patientId,
        orderId: formState?.orderId,
        stageId: formState?.stageId,
        prefInterpPhysId: e.target.name === "prefInterpPhysId" ? e.target.value : formState?.prefInterpPhysId,
        studyTypeId: formState?.studyTypeId,
      }
      ScheduleLocation(payload);
    }
  };

  const updateBMIInput = (e) => {
    const newBMIState = { ...BMI, [e.target.id]: e.target.value };
    const height =
      parseInt(newBMIState.heightFeet) * 12 +
      parseInt(newBMIState.heightInches);
    const bmi = Math.round((newBMIState.patWeight * 703) / Math.pow(height, 2));
    if (!isNaN(bmi)) {
      newBMIState.patBMI = bmi;
    }
    setBMIState(newBMIState);

    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };
  const rows = [];

  
  const handleSSNChange = (event) => {
    // Remove non-numeric characters from the input
    const rawSSN = event.target.value.replace(/\D/g, '');

    // Format the SSN as ###-##-####
    const formattedSSN = rawSSN.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
    setFormState({ ...formState, ssn: formattedSSN })
    setSubmitEnabledSSN(true)
    setIsSaveButtonDisabled(false);
  };

  const cancel = (e) => { };
  const updatePhoneNumber = (e) => {
    const { formattedPhoneNumber, numericPhoneNumber } = phoneNumberValidator(
      e.target.value
    );
    setPatientsPhoneNumber({
      ...patientsPhoneNumber,
      [e.target.id]: numericPhoneNumber,
      [`${e.target.id}Error`]: false,
    });

    setFormState((prevState) => {
      return {
        ...prevState,
        [e.target.id]: formattedPhoneNumber,
      };
    });
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };
  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        if(!formState.emergencyContact){
          errors["emerContactName"] = { error: false };
          errors["emerContactNum"] = { error: false };
        }else{
          isValid = false;
          errors[key] = { error: true };
        }
      }
    }
    
    if (!DOB) {
      isValid = false;
      setErrorDOB(true);
    }

    if (!isValid) {
      notificationToastError("Please fill all required fields");
      setFormErrorState(errors);
    } else {
      if (formState?.enablePortal && !formState?.email) {
        setEmailRequired({
          ...emailRequired,
          isEmailRequired: true,
        });
        isValid = false;
        notificationToastError("Email is required");
      } else {
        setEmailRequired({
          ...emailRequired,
          isEmailRequired: false,
        });
      }
    }
    if (formState?.enablePortal && !formState?.email) {
      setEmailRequired({
        ...emailRequired,
        isEmailRequired: true,
      });
      isValid = false;
    }

    if (
      patientsPhoneNumber?.bestPhone ||
      patientsPhoneNumber?.cellPhone ||
      patientsPhoneNumber?.parentPhoneNumber ||
      !patientsPhoneNumber?.bestPhone || 
      !patientsPhoneNumber?.cellPhone
    ) {
      
      if (
        patientsPhoneNumber?.cellPhone?.length !== 10 &&
        patientsPhoneNumber?.cellPhone?.length > 0
      ) {
        setPatientsPhoneNumber({
          ...patientsPhoneNumber,

          cellPhoneError: true,
        });
        notificationToastError("Phone number must be 10 digits");
        isValid = false;
      }

      if (
        patientsPhoneNumber?.parentPhoneNumber?.length !== 10 &&
        patientsPhoneNumber?.parentPhoneNumber?.length > 0
      ) {
        setPatientsPhoneNumber({
          ...patientsPhoneNumber,

          parentPhoneNumberError: true,
        });
        notificationToastError("Phone number must be 10 digits");
        isValid = false;
      }

      if (
        patientsPhoneNumber?.emerContactNum?.length !== 10 &&
        patientsPhoneNumber?.emerContactNum?.length > 0
      ) {
        setPatientsPhoneNumber({
          ...patientsPhoneNumber,

          emerContactNumError: true,
        });
        notificationToastError("Phone number must be 10 digits");
        isValid = false;
      }
    }
    if (formState?.email) {
      if (!validateEmail(formState?.email)) {
        isValid = false;
        setEmailRequired({
          ...emailRequired,
          isvalidEmail: false,
        });
        notificationToastError("Please enter valid email");
      }
    }
    if (
      formState?.minorPatient !== 1 &&
      (formState?.emergencyContact === 1 &&
        (!formState?.emerContactName ||
        !patientsPhoneNumber?.emerContactNum))
    ) {
      isValid = false;
      notificationToastError("Please enter Emergency Contact Details");
    }

    if (!formState?.groupId) {
      isValid = false;
      notificationToastError("Please Select Provider Group");
    }

    if (!patientsPhoneNumber?.cellPhone) {
      isValid = false;
      notificationToastError("Please Enter Cell Phone number");
    }

    if (isValid) {
      setIsSaveButtonDisabled(true)
      const payload = {
        ...formState,
        heightFeet: BMI.heightFeet,
        heightInches: BMI.heightInches,
        patBMI: BMI.patBMI,
        patWeight: BMI.patWeight,
        dob: DOB?.format("YYYY-MM-DD"),
        groupId: formState.groupId,
        patientId: formState.patientId,
        bestPhone: patientsPhoneNumber?.bestPhone,
        cellPhone: patientsPhoneNumber?.cellPhone,
        parentMobile: patientsPhoneNumber?.parentPhoneNumber,
        patientPortal: isPatientPortal ? 1 : 0,
        emerContactName:
          formState?.emergencyContact === 1 ? formState?.emerContactName : "",
        emerContactNum:
          formState?.emergencyContact === 1
            ? patientsPhoneNumber?.emerContactNum
            : "",
        expirationDate: formState?.expirationDate ? formState?.expirationDate : undefined,
        cvc: submitEnabledCVC ? formState.cvc ? formState.cvc : "1" : undefined,
        cardNumber: submitEnabledCardNum ? formState.cardNumber ? formState.cardNumber : "1" : undefined,
        nameOnCard: formState.nameOnCard ? formState.nameOnCard : undefined,
        ssn: submitEnabledSSN ? formState.ssn ? formState.ssn : "1" : undefined,
        // fallsAsleepCheckBox: fallsAsleepCheckBox ? 1 : 0,
      };
      patientsPostData(axios, "savePatientInfo", payload)
        .then((res) => {
          if (res.data.response === "1") {
            notificationToastSuccess(res?.data?.message);
            // console.log("res patientId = ", res.data);
            if (res.data.patientId) {
              const paramsName = "patientView";
              const payload = {
                patientId: res.data.patientId,
              };

              patientsPaginationGetData(axios, paramsName, payload)
                .then((res) => {
                  const updatedSelectedPatient = res?.filter(
                    (item) =>
                      String(item?.patientId) === String(payload.patientId)
                  );
                  if (updatedSelectedPatient?.length) {
                    dispatch(updateSelectedPatient(updatedSelectedPatient[0]));
                  }
                })
                .catch((err) => notificationToastError(err?.message));
            }
            if (!isPatientPortal) {
              setTimeout(() => {
                navigate(`/patients`);
              }, 2000);
            }
          } else {
            if (
              res.data.message ===
              "Patient already exists in the system. Please check it."
            ) {
              setPatientAlredyExist({
                isAlredyExist: true,
                patientId: res.data?.patientId,
              });
            } else {
              notificationToastError(res?.data?.message);
            }
          }
          setRefreshData(!refreshData);
        })
        .catch((err) => notificationToastError(err?.message));
    }
  };

  function createData(Type, DateCreated, StopBang, Actions) {
    return {
      Type,
      DateCreated,
      StopBang,
      Actions,
    };
  }
  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }
  const updateDOB = (newValue) => {
    setDOB(newValue);
    const { years, months } = calculate_age(newValue);
    setFormState({
      ...formState,
      age: years,
      months: months
    })
    if (!newValue) {
      setInputError(false);
      return 'Invalid Date';
    }
    const { $d } = newValue;

    if ($d == "Invalid Date") {
      setInputError(true);
    } else {
      setInputError(false);
    }
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };

  const updateExpirationDate = (newValue) => {
    setExpirationDate(newValue);
    if (!newValue) {
      setExpDateError(false);
      return 'Invalid Expiration Date';
    }
   /* const { $d } = newValue;

    if ($d == "Invalid Date") {
      setExpDateError(true);
    } else {
      setExpDateError(false);
    }
    */
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };

  const calculate_age = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age_years = today.getFullYear() - birthDate.getFullYear();
    let age_months = today.getMonth() - birthDate.getMonth();

    if (age_months < 0 || (age_months === 0 && today.getDate() < birthDate.getDate())) {
        age_years--;
        age_months += 12;
    }

    if (today.getDate() < birthDate.getDate()) {
        age_months--;
    }

    return { years: age_years, months: age_months };
  }

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === 0){
      setTabData(!tabData)
    }
  };

  const addInternalNotes = () => {
    if(formState?.internalLog){
      setIsDisabled(true);
      patientsPostData(axios, "updateInternalLog", { patientId: formState?.patientId, internalLog: formState?.internalLog })
      .then((res) => {
        notificationToastSuccess(res?.data?.message)
        setFormState({
          ...formState,
          internalLog: ""
        })
        setIsDisabled(false);
        patientViewGetData(axios, "getContactLog", formState?.patientId)
          .then((res) => {
            if (res) {
              setRowContactLog(res);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      })
      .catch((err) => notificationToastError(err?.message))
    }else{
      notificationToastError('Please add internal log notes')
    }
  };

  return (
    <>
      {rowStateData === 0 && value === 0 && (
        <Box
          sx={{
            position: "absolute",
            right: isMobile ? "22px" : "70px",
            top: isMobile ? "65px" : "90px",
          }}
        >
          <Typography variant="subtitle2" color="#1976d2">
            PATIENT ID: {formState?.patientNum}
          </Typography>
        </Box>
      )}

      <Container
        sx={{
          maxWidth: !isMobile
            ? isCollapsed
              ? `calc(100vw - ${collapsedDrawerWidth}px) !important`
              : `calc(100vw - ${openDrawerWidth}px) !important`
            : "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: isMobile ? "column" : "column",
          }}
        >
          {(rowStateData === 0 || patientId) && (
            <Box sx={{ bgcolor: "background.paper" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                // orientation= {isMobile  ? "horizontal" : "vertical"}
                orientation="horizontal"
                variant={isMobile ? "scrollable" : "standard"}
                scrollButtons={isMobile ? true : false}
                allowScrollButtonsMobile={isMobile ? true : false}
              >
                <Tab className={classes.subTab} label="Profile" />
                <Tab className={classes.subTab} label="Latest Visit" />
                <Tab className={classes.subTab} label="Previous Visits" />
              </Tabs>
            </Box>
          )}

          <TabPanel value={value} index={0}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
                border: "1px solid rgba(0,0,0, 0.12)",
                borderRadius: "4px",
                // marginTop: "20px",
              }}
              noValidate
              autoComplete="off"
            >
              <PatientInfo
                formState={formState}
                updateInput={updateInput}
                DOB={DOB}
                updateDOB={updateDOB}
                errorDOB={errorDOB}
                setErrorDOB={setErrorDOB}
                formErrorState={formErrorState}
                setFormErrorState={setFormErrorState}
                updatePhoneNumber={updatePhoneNumber}
                patientsPhoneNumber={patientsPhoneNumber}
                setPatientsPhoneNumber={setPatientsPhoneNumber}
                isEmailRequired={emailRequired?.isEmailRequired}
                emailRequired={emailRequired}
                isPatientPortal={isPatientPortal}
                setFormState={setFormState}
                setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                updateBMIInput={updateBMIInput}
                BMIState={BMI}
                updateCheckBox={updateCheckBox}
                inputError={inputError}
                expDateError={expDateError}
                handleSSNChange={handleSSNChange}
                ssnError={ssnError}
                setSsnError={setSsnError}
              />
              <Box
                component="div"
                sx={{
                  display: "flex",
                  marginTop: "1rem",
                }}
              >
                <Grid container sx={{ m: 1, display: "flex", flexDirection: "row",alignItems:"center" }}>
                  <Grid item lg={2.5} md={4} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="heartDisease"
                          checked={Boolean(formState.heartDisease)}
                          onChange={updateCheckBox}
                        />
                      }
                      label="Patient Has Heart OR Lung Disease"
                    />
                  </Grid>
                  <Grid item lg={2.5} md={4} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="disabilities"
                          checked={Boolean(formState.disabilities)}
                          onChange={updateCheckBox}
                        />
                      }
                      label="Other Disabilities"
                    />
                  </Grid>
                  <Grid item lg={2.5} md={4} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="specialAccomodation"
                          checked={Boolean(formState.specialAccomodation)}
                          onChange={updateCheckBox}
                        />
                      }
                      label="Need Special Accomodations"
                    />
                  </Grid>
                  <Grid item lg={2.5} md={4} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="careGiver"
                          checked={Boolean(formState.careGiver)}
                          onChange={updateCheckBox}
                        />
                      }
                      label="Care Giver Accompanying PT"
                    />
                  </Grid>
                  {formState?.careGiver ? <Grid item lg={2} md={5} sm={12} xs={12}>
                    <TextField
                      id="careGiverName"
                      label="CareGiver Name"
                      value={formState.careGiverName || ""}
                      variant="standard"
                      onChange={updateInput}
                      sx={{ width: "90%" }}
                    />
                  </Grid> : ""}
                </Grid>
              </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            marginTop: "1rem",
            justifyContent: "space-between",
          }}
        >
          <Grid container>
            <Grid item lg={6} md={4} sm={12} xs={12}>
              <GroupPulldown
                formState={formState}
                updateInput={updateInput}
                formErrorState={formErrorState}
                isPatientPortal={isPatientPortal}
              />
            </Grid>

            <Grid item lg={6} md={4} sm={12} xs={12}>
              <TextField
                id="primaryCarePhysician"
                label="Primary Care Physician"
                value={formState.primaryCarePhysician || ""}
                variant="standard"
                onChange={updateInput}
                sx={{ width: "90%" }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            marginTop: "1rem",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                    <FormControl sx={{ m: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="enablePortal"
                            checked={Boolean(formState.enablePortal)}
                            onChange={isPatientPortal ? "" : updateCheckBox}
                          />
                        }
                        label="Enable Patient Portal"
                      />
                    </FormControl>
                  </Grid> */}

                  <AgeCalculator
                    formState={formState}
                    updateCheckBox={updateCheckBox}
                    DOB={DOB}
                    setFormState={setFormState}
                    setFormErrorState={setFormErrorState}
                  
                  />

                  <Grid
                    item
                    lg={1}
                    md={4}
                    sm={12}
                    xs={12}
                    sx={{ textAlign: "center" }}
                  >
                    <Typography> OR </Typography>
                  </Grid>
                  <Grid item lg={3} md={4} sm={12} xs={12}>
                    <FormControl sx={{ m: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="emergencyContact"
                            checked={Boolean(formState?.emergencyContact)}
                            onChange={updateCheckBox}
                          />
                        }
                        label="Emergency Contact"
                      />
                    </FormControl>
                  </Grid>

            {formState?.emergencyContact === 1 && (
              <>
                <Grid item lg={3} md={5} sm={12} xs={12}>
                  <TextField
                    id="emerContactName"
                    label="Name *"
                    value={formState.emerContactName || ""}
                    variant="standard"
                    // inputProps={{ readOnly: isReadOnly }}
                    onChange={updateInput}
                    // onMouseDown={handleFocus}
                    sx={{ width: "90%" }}
                    error={Boolean(formState?.emergencyContact) && formErrorState?.emerContactName?.error && !formState?.emerContactName}
                    helperText={
                      Boolean(formState?.emergencyContact) && formErrorState?.emerContactName?.error
                         ? "required"
                        : ""
                      }
                    onBlur={validateEmptyField.bind(null, "emerContactName")}
                  />
                </Grid>

                <Grid item lg={3} md={4} sm={12} xs={12}>
                  <TextField
                    id="emerContactNum"
                    label="Contact Number *"
                    value={formState.emerContactNum || ""}
                    variant="standard"
                    // inputProps={{ readOnly: isReadOnly }}
                    // onMouseDown={handleFocus}
                    onChange={updatePhoneNumber}
                    error={formErrorState?.emerContactNum?.error || patientsPhoneNumber.emerContactNumError}
                    onBlur={() => {
                      validateEmptyField("emerContactNum")
                      if (
                        patientsPhoneNumber?.emerContactNum?.length <
                        10 &&
                        patientsPhoneNumber?.emerContactNum?.length > 0
                      ) {
                        setPatientsPhoneNumber({
                          ...patientsPhoneNumber,
                          emerContactNumError: true,
                        });
                      }
                    }}
                    sx={{ width: "90%" }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>


              {formState?.minorPatient === 1 && (
                <MinorPatients
                  formState={formState}
                  updateInput={updateInput}
                  formErrorState={formErrorState}
                  validateEmptyField={validateEmptyField}
                  patientsPhoneNumber={patientsPhoneNumber}
                  setPatientsPhoneNumber={setPatientsPhoneNumber}
                  updatePhoneNumber={updatePhoneNumber}
                />
              )}

             
              <InsuranceInfo
                formState={formState}
                setFormState={setFormState}
                setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                isPatientPortal={isPatientPortal}
              />

              {
                roleId === 1 || roleId === 3 || roleId === 6  ?"":
                (Array.isArray(userGroupId) && userGroupId.includes(1) || userGroupId === 1) && previleges?.includes("viewEncryptedInfo") &&
                <CCInformation formState={formState} setFormState={setFormState} previleges={previleges} setSubmitEnabledCardNum={setSubmitEnabledCardNum} setSubmitEnabledCVC={setSubmitEnabledCVC} setSubmitEnabled={setSubmitEnabled} setIsSaveButtonDisabled={setIsSaveButtonDisabled} updateExpirationDate={updateExpirationDate} expirationDate={expirationDate} errorexpirationDate={errorexpirationDate} expDateError={expDateError} setErrorExpirationDate={setErrorExpirationDate} updateInput={updateInput} handleSSNChange={handleSSNChange} ssnError={ssnError} setSsnError={setSsnError}/>
              }

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "98%" },
                  border: "1px solid rgba(0,0,0, 0.12)",
                  borderRadius: "4px",
                  marginTop: "10px",
                }}
              ></Box>
            </Box>

            <Box sx={{ paddingTop: "2rem", paddingBottom: "1rem" }}>
              {patientAlredyExist?.isAlredyExist && (
                <Box mb={2}>
                  <Alert severity="info">
                    Patient already exist in system,{" "}
                    <Typography
                      variant="linkUnderLine"
                      onClick={handlePatientView}
                    >
                      click here
                    </Typography>{" "}
                    to view patient detail{" "}
                  </Alert>
                </Box>
              )}

              <Button
                variant="contained"
                disabled={isSaveButtonDisabled}
                onClick={saveChanges}
              >
                SAVE CHANGES
              </Button>

              <Button
                sx={{ marginLeft: "1rem" }}
                variant="contained"
                onClick={cancel}
              >
                CANCEL
              </Button>
            </Box>
            {isPatientPortal && (
              <UpdatePassword isPatientPortal={isPatientPortal} />
            )}

             {/* Order Info */}
            {location.state?.searchPatientInfo?.addNewPatient || (Array.isArray(userGroupId) && !userGroupId.includes(1) && userGroupId !== 1) || (!previleges?.includes('orders') && !previleges?.includes('referral')) ? "" :
              <Accordion> 
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="strong">Upload Signed Order</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <OrderInfo
                    formState={formState}
                    updateInput={updateInput}
                    DOB={DOB}
                    updateDOB={updateDOB}
                    errorDOB={errorDOB}
                    setErrorDOB={setErrorDOB}
                    formErrorState={formErrorState}
                    setFormErrorState={setFormErrorState}
                    updatePhoneNumber={updatePhoneNumber}
                    patientsPhoneNumber={patientsPhoneNumber}
                    setPatientsPhoneNumber={setPatientsPhoneNumber}
                    isEmailRequired={emailRequired?.isEmailRequired}
                    emailRequired={emailRequired}
                    isPatientPortal={isPatientPortal}
                    setFormState={setFormState}
                    setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                    updateCheckBox={updateCheckBox}
                    selectedCheckbox={selectedCheckbox}
                    setSelectedCheckbox={setSelectedCheckbox}
                    validateEmptyField={validateEmptyField}
                    locations={locations}
                    setLocations={setLocations}
                    fromPatientView={true}
                  />
                </AccordionDetails>
              </Accordion>} 
            
            {
              location.state?.searchPatientInfo?.addNewPatient ? "" : <>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1 },
                    border: "1px solid rgba(0,0,0, 0.12)",
                    borderRadius: "4px",
                    marginTop: "10px",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <h4
                    style={{
                      paddingLeft: "0.5rem",
                      paddingTop: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    Forms
                  </h4>
                  <MUIDataTable
                    data={rows}
                    columns={["Type", "Date Created", "STOPBang", "Actions"]}
                    options={{
                      selectableRows: 'none', // <===== will turn off checkboxes in rows
                    }}
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1 },
                    border: "1px solid rgba(0,0,0, 0.12)",
                    borderRadius: "4px",
                    marginTop: "20px",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <h4
                    style={{
                      paddingLeft: "0.5rem",
                    }}
                  >
                    Files
                  </h4>
                  <FileUpload
                    patientId={formState?.patientId}
                    setRefreshData={setRefreshData}
                    refreshData={refreshData}
                    isPatientPortal={isPatientPortal}
                  />
                  <MUIDataTable
                    data={rowData}
                    columns={[
                      {
                        name: "fileId",
                        options: {
                          display: false,
                          viewColumns: false,
                          filter: false,
                        },
                      },
                      {
                        name: "patientId",
                        options: {
                          display: false,
                          viewColumns: false,
                          filter: false,
                        },
                      },
                      {
                        name: "fileName",
                        label: "File Name",
                      },
                      {
                        name: "category",
                        label: "Category",
                      },
                      {
                        name: "storedDate",
                        label: "Date Created",
                      },

                      {
                        name: "Actions",
                        label: "Actions",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                              <Box sx={{ display: "flex", gap: 1 }}>
                                <Tooltip title="View" placement="bottom">
                                  <Button
                                    sx={{ width: 60, marginTop: "1rem" }}
                                    variant="contained"
                                    size="small"
                                    onClick={download.bind(
                                      null,
                                      tableMeta.rowData ? tableMeta.rowData[0] : "",
                                      "storedFiles",
                                      "fileId",
                                      "view"
                                    )}
                                  >
                                    <Visibility />
                                  </Button>
                                </Tooltip>

                                <Tooltip title="Download" placement="bottom">
                                  <Button
                                    sx={{ width: 60, marginTop: "1rem" }}
                                    variant="contained"
                                    size="small"
                                    onClick={download.bind(
                                      null,
                                      tableMeta.rowData ? tableMeta.rowData[0] : "",
                                      "storedFiles",
                                      "fileId"
                                    )}
                                  >
                                    <Download />
                                  </Button>
                                </Tooltip>

                                <Tooltip title="Delete" placement="bottom">
                                  <Button
                                    sx={{ width: 60, marginTop: "1rem" }}
                                    variant="contained"
                                    size="small"
                                    onClick={deleteRecord.bind(
                                      null,
                                      tableMeta.rowData ? tableMeta.rowData[0] : "",
                                      "storedFiles",
                                      "fileId"
                                    )}
                                  >
                                    <Delete />
                                  </Button>
                                </Tooltip>
                              </Box>
                            );
                          },
                        },
                      },
                    ]}
                    options={{
                      selectableRows: 'none', // <===== will turn off checkboxes in rows
                    }}
                  />
                </Box>
              </>
            }
           
          </TabPanel>

          {/* current visits component */}
          <TabPanel value={value} index={1}>
            <CurrentVisits formState={formState} setFormState={setFormState} />
          </TabPanel>

          {/* previos visits component */}
          <TabPanel value={value} index={2}>
            <PriviousVisits formState={formState} />
          </TabPanel>
        </Box>
        {openPdfModal && (
          <FileView
            setOpenPdfModal={setOpenPdfModal}
            openPdfModal={openPdfModal}
            pdfURLState={pdfURLState}
          />
        )}
        
        {
          location.state?.searchPatientInfo?.addNewPatient || isPatientPortal ? "" :
            <>
              {(Array.isArray(userGroupId) && userGroupId.includes(1) || userGroupId === 1) &&
                <Box sx={{
                  border: "1px solid rgba(0,0,0, 0.12)",
                  borderRadius: "4px",
                  marginTop: "20px",
                }}>
                  <FormControl
                    variant="standard"
                    sx={{ width: "100%", }}
                  >
                    <h4 style={{ paddingLeft: "0.2rem" }} > Internal Notes </h4>

                    <TextareaAutosize
                      id="internalLog"
                      label="Notes"
                      value={formState?.internalLog || ""}
                      variant="standard"
                      onChange={updateInput}
                      multiline
                      rows={10}
                      style={{ height: "100px", margin: "5px" }}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={addInternalNotes}
                    disabled={isDisabled}
                    sx={{ margin: "5px", }}
                  >
                    Add Internal Notes
                  </Button>
                </Box>
              }
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1 },
                  border: "1px solid rgba(0,0,0, 0.12)",
                  borderRadius: "4px",
                  marginTop: "20px",
                }}
                noValidate
                autoComplete="off"
              >
                <h4
                  style={{
                    paddingLeft: "0.5rem",
                  }}
                >
                  Contact Logs
                </h4>
                <PatientContactLog
                  formState={formState}
                  rowContactLog={rowContactLog}
                />
              </Box>
            </>
            
        } 
        <ToastContainer />
      </Container>
    </>
  );
}

export default PatientView;