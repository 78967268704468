import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard";
import Patients from "./pages/patients/Patients";
import Admin from "./components/Admin";
import "./App.scss";
import SignIn from "./components/SignIn";
import { Navigate, Outlet } from "../node_modules/react-router-dom/dist/index";
import React, { useState } from "react";
import { useAuth } from "./hooks/auth";
import AppProvider from "./hooks/AppProvider";
import { TableData } from "./components/TableData";
import NewSurvey from "./pages/patients/components/NewSurvey";
import SurveyView from "./pages/patients/components/SurveyView";
import PhysicianProfile from "./pages/PhysicianProfile";
import NewOrder from "./pages/patients/components/newOrder/NewOrder";
import OrderView from "./pages/patients/components/OrderView";
import PatientView from "./pages/patients/components/PatientView";
import UserView from "./pages/UserView";
import GroupView from "./pages/GroupView";
import RoleView from "./pages/RoleView";
import MuiAlert from "@mui/material/Alert";
import LocationView from "./pages/LocationView";
import InsuranceView from "./pages/InsuranceView";
import DeleteRecord from "./pages/DeleteRecord";
import AddCarrier from "./pages/AddCarrier";
import StopBangQuestionaire from "./pages/forms/StopBangQuestionaire";
import BerlinQuestionaire from "./pages/forms/BerlinQuestionaire";
import SatisfactionSurvey from "./pages/forms/SatisfactionSurvey";
import QualityImprovementSurvey from "./pages/forms/QualityImprovementSurvey";
import EpworthSleepinessScale from "./pages/forms/EpworthSleepinessScale";
import InterpPhysView from "./pages/InterpPhysView";
import roleAndPrivilegesFnc from "./utils/roleAndPriviledges";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbarMessage } from "./store/slices/snackBarSlice";
import { Box, CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "./Theme/theme";
import SignInLanding from "./pages/patientPortal/signInLanding/SignInLanding";
import ForgotPassword from "./pages/patientPortal/signInLanding/ForgotPassword";
import PatientPortalHomePage from "./pages/patientPortal/patientPortalHomePage/PatientPortalHomePage";
import PatientPortalPastVisits from "./pages/patientPortal/patientPortalPastVisits/PatientPortalPastVisits";
import ContactUs from "./pages/patientPortal/contactUs/ContactUs";
import PatientPortalProfilePage from "./pages/patientPortal/patientPortalProfile/PatientPortalProfilePage";
import SignInPatient from "./pages/patientPortal/signInLanding/SignInPatient";
import NotFoundPage from "./pages/pageNotFound/NotFoundPage";
import { useCookies } from "react-cookie";
import DmeOrder from "./pages/patients/dmeOrder/DmeOrder";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Reports from "./pages/reports/Reports";
import { TimeoutLogic  } from './timeout'
import useSessionTimeout from "./useSessionTimeout";
import { ErrorProvider } from "./Context/ErrorContext";
import AuthLogin from "./components/AuthLogin";

export const ProtectRoutes = () => {
  const { cookies, isLoading } = useAuth();
  let redirectPath;
  if (cookies?.token) {
    const { tabPath } = roleAndPrivilegesFnc(cookies?.token);
    redirectPath = tabPath;
  }
  return isLoading ? (
    <Box
        sx={{
          display: "flex",
          height: "90vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box> // Or use a spinner component
  ) : cookies?.token ? (
    <Layout tabPath={redirectPath} />
  ) : (
    <>
      <Navigate to="/signin-landing" exact />
    </>
  );
};

export const PatientPoratlProtectRoutes = () => {
  const { cookies } = useAuth();
  
  return cookies?.token && cookies?.isPatient ? (
    <Layout isPatient={cookies?.isPatient}/>
  ) : (
    <>
      <Navigate to="/signin-landing" exact />
    </>
  );
};
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const [cookies] = useCookies();
  const { messageObject } = useSelector((state) => state.snackBarSelector);
  const dispatch = useDispatch();
  useSessionTimeout();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppProvider>
        <ErrorProvider>
          {/* <TimeoutLogic /> */}
          <Snackbar
            open={!!messageObject?.message}
            autoHideDuration={3000}
            onClose={() => {
              dispatch(setSnackbarMessage(null));
            }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={() => {
                dispatch(setSnackbarMessage(null));
              }}
              severity={messageObject?.type}
              sx={{ width: "100%" }}
            >
              {messageObject?.message}
            </Alert>
          </Snackbar>
          <Routes>
            <Route path="/authlogin" element={<AuthLogin />} />
            <Route path="/signin" index element={<SignIn />} />
            <Route path="/signin-landing" index element={cookies?.token ? <Navigate to="/" /> : <SignInLanding />} />
            <Route
              path="/signin-as-patient"
              index
              element={<SignInPatient />}
            />
             <Route path="/forgot-password" index element={< ForgotPassword />} />
            <Route path="*" element={<NotFoundPage />} />

            {
              // protected route for testPortal
              cookies?.token && !cookies?.isPatient && (
                <Route path="/" element={<ErrorProvider><ProtectRoutes /></ErrorProvider>}>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="patients" element={<Patients />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="admin" element={<Admin />} />
                  <Route
                    path="physician-profile"
                    element={<PhysicianProfile />}
                  />
                  <Route path="new-survey" element={<NewSurvey />} />
                  <Route path="new-order" element={<NewOrder />} />
                  <Route path="patient-view" element={<PatientView />} />
                  <Route path="user-view" element={<UserView />} />
                  <Route path="group-view" element={<GroupView />} />
                  <Route path="role-view" element={<RoleView />} />
                  <Route path="location-view" element={<LocationView />} />
                  <Route path="insurance-view" element={<InsuranceView />} />
                  <Route path="delete-record" element={<DeleteRecord />} />
                  <Route path="add-carrier" element={<AddCarrier />} />
                  <Route path="stop-bang" element={<StopBangQuestionaire />} />
                  <Route
                    path="berlin-questionaire"
                    element={<BerlinQuestionaire />}
                  />
                  <Route
                    path="satisfaction-survey"
                    element={<SatisfactionSurvey />}
                  />
                  <Route
                    path="quality-improvement-survey"
                    element={<QualityImprovementSurvey />}
                  />
                  <Route
                    path="epworth-sleepiness-scale"
                    element={<EpworthSleepinessScale />}
                  />
                  <Route path="interp-phys-view" element={<InterpPhysView />} />
                  <Route path="survey-view" element={<SurveyView />} />
                  <Route path="order-view" element={<OrderView />} />

                  <Route path="example" element={<TableData />} />
                  {/* dme order routes */}

                  <Route path="dme-order" element={<DmeOrder />} />
                </Route>
              )
            }
            {
              // protected route for patientPortal
              cookies?.token && cookies?.isPatient && (
                <Route path="/" element={ <ErrorProvider><PatientPoratlProtectRoutes /></ErrorProvider>}>
                  <Route path="/" element={<Navigate to="/patient-portal" />} />
                  <Route
                    path="patient-portal"
                    element={<PatientPortalHomePage />}
                  />
                  <Route
                    path="patient-profile"
                    element={<PatientPortalProfilePage />}
                  />
                  <Route
                    path="patient-past-visits"
                    element={<PatientPortalPastVisits />}
                  />
                  <Route path="contact-us" element={<ContactUs />} />
                </Route>
              )
            }
          </Routes>
          <ToastContainer />
          </ErrorProvider>
        </AppProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
