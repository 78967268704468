import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import ReschedulePopup from "./ReschedulePopup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import GeneralPulldown from "./pulldowns/GeneralPulldown";
import { dmeStudyPostData, studyPostData } from "../../axios/api";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Divider,
} from "@mui/material";

export default function GetAuthorizationDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess = () => {},
  notificationToastError = () => {},
  asDialog = true,
  isPreviousVisits = false,
  authorizationInfo = {},
  isPatientPortal,
  notShowPopup,
  reloadCurrentVisit,
  isWellnecessitiesUser,
  isCurrentVisits
}) {
  // console.log("isPreviousVisits==>",isPreviousVisits);
  const [formErrorState, setFormErrorState] = useState({});
  // const { cookies } = useAuth();
  // const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const axios = useAxios();
  const paramNameIn = "name";
  const updateCheckBox = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
  };

  React.useEffect(() => {
    if (row) {
      setFormState({
        physicianId: row?.physicianIdOrder,
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        prefInterpPhysId: row?.prefInterpPhysId,
        studyId: row?.studyIdStudy,
        activeAuthorization: row?.activeAuthorization,
        authorizedOn: row?.authorizedOn,
        authorizationStartDate: row?.authorizationStartDate,
        authorizationEndDate: row?.authorizationEndDate,
        authorizationDenied: row?.authorizationDenied,
        reasonAuthDenial: row?.reasonAuthDenial,
        reasonAuthDenialId: row?.reasonAuthDenialId,
        VDATE: row?.insVerifiedDate,
        PDATE: row?.authorizationStartDate,
        EDATE: row?.authorizationEndDate,
        authNum: row?.authNum,
        priorAuthRequired: row?.priorAuthRequired,
        priorAuthRequired2: row?.priorAuthRequired2,
        priorAuthRequired3: row?.priorAuthRequired3,
        activeAuthorization2:row?.activeAuthorization2,
        authorizedOn2: row?.authorizedOn2,
        authNum2: row?.authNum2,
        authorizationStartDate2: row?.authorizationStartDate2,
        authorizationEndDate2: row?.authorizationEndDate2,
        authorizationDenied2: row?.authorizationDenied2,
        reasonAuthDenialId2: row?.reasonAuthDenialId2,
        reasonAuthDenial3: row?.reasonAuthDenial3,
        activeAuthorization3:row?.activeAuthorization3,
        authorizedOn3: row?.authorizedOn3,
        authNum3: row?.authNum3,
        authorizationStartDate3: row?.authorizationStartDate3,
        authorizationEndDate3: row?.authorizationEndDate3,
        authorizationDenied3: row?.authorizationDenied3,
        reasonAuthDenialId3: row?.reasonAuthDenialId3,
        reasonAuthDenial3: row?.reasonAuthDenial3,
        dmeOrderId: row?.dmeOrderIdOrder,
      });
      setPDATE(row?.authorizationStartDate)
      setEDATE(row?.authorizationEndDate)
      setVDATE(row?.authorizedOn);
      setSDATE(row?.followUpAuthorizationDate)
    } else if (authorizationInfo) {
      // console.log("authorizationInfo=====>",authorizationInfo);
      setFormState({ ...formState, ...authorizationInfo });
      setPDATE(authorizationInfo?.authorizationStartDate);
      setEDATE(authorizationInfo?.authorizationEndDate);
      setVDATE(authorizationInfo?.authorizedOn);
      setSDATE(authorizationInfo?.followUpAuthorizationDate)
    }
  }, [row]);

  const updateInput = (e) => {
    console.log('e.target.id || e.target.name]: e.target.value',e.target, e.target.id, e.target.name, e.target.value)
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };

  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const saveChanges = (e) => {
    const formStateError = { ...formErrorState };
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (formState.authorizationDenied && !formState.reasonAuthDenial) {
      isValid = false;
      formStateError["reasonAuthDenial"] = { error: true };
    }

    if(!formState.reasonAuthDenial && formState?.activeAuthorization && (!VDATE || !formState?.authNum || !PDATE || !EDATE)){
      isValid = false;
      notificationToastError("Please fill primary insurance authorization fields")
      return false;
    }

    if(!formState.reasonAuthDenial2 && formState?.activeAuthorization2 && (!formState?.authorizedOn2 || !formState?.authNum2 || !formState?.authorizationStartDate2 || !formState?.authorizationEndDate2)){
       isValid = false;
       notificationToastError("Please fill secondary insurance authorization fields")
       return false;
     }

     if(!formState.reasonAuthDenial3 && formState?.activeAuthorization3 && (!formState?.authorizedOn3 || !formState?.authNum3 || !formState?.authorizationStartDate3 || !formState?.authorizationEndDate3)){
       isValid = false;
       notificationToastError("Please fill tertiary insurance authorization fields")
       return false;
     }

    if (!isValid) {
      // setFormErrorState(errors);
      notificationToastError("Please fill all required fields")
      setErrorMessage("Please fill all required fields");
      setFormErrorState(formStateError);
      return false;
    }
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      authorizedOn: VDATE ? moment(VDATE).format('YYYY-MM-DD') : "",
      authorizationStartDate: PDATE ? moment(PDATE).format('YYYY-MM-DD') : "",
      authorizationEndDate: EDATE ? moment(EDATE).format('YYYY-MM-DD') : "",
      followUpAuthorizationDate: SDATE ? moment(SDATE).format('YYYY-MM-DD') : ""
    };
    if (formState?.dmeOrderId > 0 && !formState?.orderId) {
      dmeStudyPostData(axios, "saveAuthorization", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            notificationToastSuccess(res?.data?.message);
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((err) => {
          notificationToastError(err?.message);
        });
      notificationToastSuccess("success");
      setTimeout(() => {
        setViewCloseDialogState(false);
      }, 2000);
    } else {
    studyPostData(axios, "saveAuthorization", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
          notificationToastSuccess("success");
          setTimeout(() => {
            setViewCloseDialogState(false);
          }, 2000);
          if (reloadCurrentVisit) reloadCurrentVisit(payload);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    }
  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [SDATE, setSDATE] = React.useState(null);
  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const updateSDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    newValue && setSDATE(newValue.format("YYYY-MM-DD"));
  };

  const [PDATE, setPDATE] = React.useState(null);
  const [errorPDATE, setErrorPDATE] = React.useState(false);
  const updatePDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    newValue && setPDATE(newValue.format("YYYY-MM-DD"));
  };

  const [EDATE, setEDATE] = React.useState(null);
  const [errorEDATE, setErrorEDATE] = React.useState(false);
  const updateEDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    newValue && setEDATE(newValue.format("YYYY-MM-DD"));
  };

  const [VDATE, setVDATE] = React.useState(null);
  const [errorVDATE, setErrorVDATE] = React.useState(false);

  const updateVDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    newValue && setVDATE(newValue.format("YYYY-MM-DD"));
  };

  const updateDate = (newValue,name) =>{
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({ ...formState, [name]: newValue.format("YYYY-MM-DD") })
  }

  let patientNameVal;
  if (row) {
    patientNameVal = row?.patientName;
  }
  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }
  function renderForm(asDialog) {
    return (
      <Box data-testid="Authorization-On-Dialog-render">
        <div>
          <FormControl sx={{ m: "8px 0px" }}>
            <Typography variant="h6" data-testid="Authorization-On-title-text">
              {asDialog ? "Authorization Info :" : "Authorization Info : "}
            </Typography>
          </FormControl>
        </div>
       {!notShowPopup && <ReschedulePopup row={row} />}

       

       {/* primary block */}

       {
          formState?.priorAuthRequired ? 
          <>
         {isCurrentVisits ? "" : <Divider sx={{ mt: 2 , border:"2px solid rgba(0, 0, 0, 0.12)"}} /> }
          <Box sx={{ marginTop: 2}}> 
            <Typography fontWeight={'bold'} fontSize={'18px'}>Primary</Typography>
              {formState?.authorizationDenied != 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="activeAuthorization"
                        checked={Boolean(formState.activeAuthorization)}
                        onChange={isPreviousVisits ? () => { } : updateCheckBox}
                      />
                    }
                    label="Active Authorization"
                  />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Authorized On (Date)"
                      inputFormat="MM/DD/YYYY"
                      value={VDATE || ""}
                      onChange={updateVDATE}
                      readOnly={isPreviousVisits}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={formState.activeAuthorization && !VDATE}
                          helperText={formState.activeAuthorization && !VDATE ? "required" : ""}
                          value={
                            VDATE
                              ? moment
                                .utc(VDATE.toLocaleString())
                                .format("MM/DD/YYYY")
                              : ""
                          }
                          inputProps={{ readOnly: isPreviousVisits }}
                          onMouseDown={handleFocus}
                          placeholder="mm/dd/yyyy"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <TextField
                    id="authNum"
                    label="Authorization Number"
                    value={formState?.authNum || ""}
                    error={formState?.activeAuthorization && !formState?.authNum}
                    helperText={formState?.activeAuthorization && !formState?.authNum ? "required" : ""}
                    variant="standard"
                    onChange={updateInput}

                  />
                </Box>
              )}
              {formState?.authorizationDenied != 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="MM/DD/YYYY"
                      value={PDATE || formState.PDATE || ""}
                      onChange={updatePDATE}
                      readOnly={isPreviousVisits}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={formState.activeAuthorization && !PDATE}
                          helperText={formState.activeAuthorization && !PDATE ? "required" : ""}
                          value={
                            PDATE
                              ? moment
                                .utc(PDATE.toLocaleString())
                                .format("MM/DD/YYYY")
                              : ""
                          }
                          inputProps={{ readOnly: isPreviousVisits }}
                          onMouseDown={handleFocus}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="MM/DD/YYYY"
                      value={EDATE || formState.EDATE || ""}
                      onChange={updateEDATE}
                      readOnly={isPreviousVisits}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={formState.activeAuthorization && !EDATE}
                          helperText={formState.activeAuthorization && !EDATE ? "required" : ""}
                          value={
                            EDATE
                              ? moment
                                .utc(EDATE.toLocaleString())
                                .format("MM/DD/YYYY")
                              : ""
                          }
                          inputProps={{ readOnly: isPreviousVisits }}
                          onMouseDown={handleFocus}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              )}

              {formState?.activeAuthorization != 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="authorizationDenied"
                        checked={Boolean(formState.authorizationDenied)}
                        onChange={isPreviousVisits ? () => { } : updateCheckBox}
                      />
                    }
                    label="Authorization Denied"
                  />
                </Box>
              )}
              {formState?.authorizationDenied === 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    // display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <GeneralPulldown
                    key="reasonAuthDenialId"
                    isPreviousVisits={isPreviousVisits}
                    formState={formState}
                    updateInput={updateInput}
                    formErrorState={formErrorState}
                    attributeName="reasonAuthDenialId"
                    style={{ width: "100%" }}
                    isPatientPortal={isPatientPortal}
                    pulldownApiName="ReasonAuthDenialPulldown"
                    itemName="reason"
                    labelName="Select Reason For Auth Denial *"
                    dbKeyColumn="reasonAuthDenialId"
                  />

                  <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: "100%" }}>
                    <TextField
                      id="reasonAuthDenial"
                      {...getErrorValidationProperty("studyDeclinedReason")}
                      label="Notes"
                      variant="standard"
                      onChange={updateInput}
                      value={formState.reasonAuthDenial}
                      error={
                        formErrorState?.reasonAuthDenial?.error &&
                        !formState?.reasonAuthDenial
                      }
                      /* helperText={
                    formErrorState?.reasonAuthDenial?.error &&
                      !formState?.reasonAuthDenial
                      ? "required"
                      : ""
                  } */
                      inputProps={{ readOnly: isPreviousVisits }}
                      onMouseDown={handleFocus}
                    />
                  </FormControl>
                </Box>
              )}
          </Box>
          </> :""
       }

        {/* secondary block */}

        {
          formState?.priorAuthRequired2 ?
          <>
            <Divider sx={{ mt: 2, border: "2px solid rgba(0, 0, 0, 0.12)" }} />

            <Box sx={{ marginTop: 2 }}>
              <Typography fontWeight={'bold'} fontSize={'18px'}>Secondary</Typography>
              {formState?.authorizationDenied2 != 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="activeAuthorization2"
                        checked={Boolean(formState.activeAuthorization2)}
                        onChange={isPreviousVisits ? () => { } : updateCheckBox}
                      />
                    }
                    label="Active Authorization"
                  />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Authorized On (Date)"
                      inputFormat="MM/DD/YYYY"
                      value={formState.authorizedOn2 || ""}
                      onChange={(e) => updateDate(e, 'authorizedOn2')}
                      readOnly={isPreviousVisits}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={formState.activeAuthorization2 && !formState.authorizedOn2}
                          helperText={formState.activeAuthorization2 && !formState.authorizedOn2 ? "required" : ""}
                          value={
                            formState.authorizedOn2
                              ? moment
                                .utc(formState.authorizedOn2.toLocaleString())
                                .format("MM/DD/YYYY")
                              : ""
                          }
                          inputProps={{ readOnly: isPreviousVisits }}
                          onMouseDown={handleFocus}
                          placeholder="mm/dd/yyyy"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <TextField
                    id="authNum2"
                    label="Authorization Number"
                    value={formState?.authNum2 || ""}
                    error={formState?.activeAuthorization2 && !formState?.authNum2}
                    helperText={formState?.activeAuthorization2 && !formState?.authNum2 ? "required" : ""}
                    variant="standard"
                    onChange={updateInput}
                  />
                </Box>
              )}
              {formState?.authorizationDenied2 != 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="MM/DD/YYYY"
                      value={formState.authorizationStartDate2 || ""}
                      onChange={(e) => updateDate(e, 'authorizationStartDate2')}
                      readOnly={isPreviousVisits}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={formState.activeAuthorization2 && !formState.authorizationStartDate2}
                          helperText={formState.activeAuthorization2 && !formState.authorizationStartDate2 ? "required" : ""}
                          value={
                            formState.authorizationStartDate2
                              ? moment
                                .utc(formState.authorizationStartDate2.toLocaleString())
                                .format("MM/DD/YYYY")
                              : ""
                          }
                          inputProps={{ readOnly: isPreviousVisits }}
                          onMouseDown={handleFocus}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="MM/DD/YYYY"
                      value={formState.authorizationEndDate2 || ""}
                      onChange={(e) => updateDate(e, 'authorizationEndDate2')}
                      readOnly={isPreviousVisits}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={formState.activeAuthorization2 && !formState.authorizationEndDate2}
                          helperText={formState.activeAuthorization2 && !formState.authorizationEndDate2 ? "required" : ""}
                          value={
                            formState.authorizationEndDate2
                              ? moment
                                .utc(formState.authorizationEndDate2.toLocaleString())
                                .format("MM/DD/YYYY")
                              : ""
                          }
                          inputProps={{ readOnly: isPreviousVisits }}
                          onMouseDown={handleFocus}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              )}

              {formState?.activeAuthorization2 != 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="authorizationDenied2"
                        checked={Boolean(formState.authorizationDenied2)}
                        onChange={isPreviousVisits ? () => { } : updateCheckBox}
                      />
                    }
                    label="Authorization Denied"
                  />
                </Box>
              )}
              {formState?.authorizationDenied2 === 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    // display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <GeneralPulldown
                    key="reasonAuthDenialId2"
                    isPreviousVisits={isPreviousVisits}
                    formState={formState}
                    updateInput={updateInput}
                    formErrorState={formErrorState}
                    attributeName="reasonAuthDenialId2"
                    style={{ width: "100%" }}
                    isPatientPortal={isPatientPortal}
                    pulldownApiName="ReasonAuthDenialPulldown"
                    itemName="reason"
                    labelName="Select Reason For Auth Denial *"
                    dbKeyColumn="reasonAuthDenialId"
                  />

                  <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: "100%" }}>
                    <TextField
                      id="reasonAuthDenial2"
                      {...getErrorValidationProperty("studyDeclinedReason")}
                      label="Notes"
                      variant="standard"
                      onChange={updateInput}
                      value={formState.reasonAuthDenial2}
                      error={
                        formErrorState?.reasonAuthDenial2?.error &&
                        !formState?.reasonAuthDenial2
                      }
                      /* helperText={
                    formErrorState?.reasonAuthDenial?.error &&
                      !formState?.reasonAuthDenial
                      ? "required"
                      : ""
                  } */
                      inputProps={{ readOnly: isPreviousVisits }}
                      onMouseDown={handleFocus}
                    />
                  </FormControl>
                </Box>
              )}
            </Box>
          </>:""
        }

        {/* tertiary block */}

        {
          formState?.priorAuthRequired3 ?
          <>
            <Divider sx={{ mt: 2, border: "2px solid rgba(0, 0, 0, 0.12)" }} />

            <Box sx={{ marginTop: 2 }}>
              <Typography fontWeight={'bold'} fontSize={'18px'}>Tertiary</Typography>
              {formState?.authorizationDenied3 != 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="activeAuthorization3"
                        checked={Boolean(formState.activeAuthorization3)}
                        onChange={isPreviousVisits ? () => { } : updateCheckBox}
                      />
                    }
                    label="Active Authorization"
                  />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Authorized On (Date)"
                      inputFormat="MM/DD/YYYY"
                      value={VDATE || formState.authorizedOn3 || ""}
                      onChange={(e) => updateDate(e, 'authorizedOn3')}
                      readOnly={isPreviousVisits}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={formState.activeAuthorization3 && !formState.authorizedOn3}
                          helperText={formState.activeAuthorization3 && !formState.authorizedOn3 ? "required" : ""}
                          value={
                            formState.authorizedOn3
                              ? moment
                                .utc(formState.authorizedOn3.toLocaleString())
                                .format("MM/DD/YYYY")
                              : ""
                          }
                          inputProps={{ readOnly: isPreviousVisits }}
                          onMouseDown={handleFocus}
                          placeholder="mm/dd/yyyy"
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <TextField
                    id="authNum3"
                    label="Authorization Number"
                    value={formState?.authNum3 || ""}
                    error={formState?.activeAuthorization3 && !formState?.authNum3}
                    helperText={formState?.activeAuthorization3 && !formState?.authNum3 ? "required" : ""}
                    variant="standard"
                    onChange={updateInput}

                  />
                </Box>
              )}
              {formState?.authorizationDenied3 != 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="MM/DD/YYYY"
                      value={formState.authorizationStartDate3 || ""}
                      onChange={(e) => updateDate(e, 'authorizationStartDate3')}
                      readOnly={isPreviousVisits}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={formState.activeAuthorization3 && !formState.authorizationStartDate3}
                          helperText={formState.activeAuthorization3 && !formState.authorizationStartDate3 ? "required" : ""}
                          value={
                            formState.authorizationStartDate3
                              ? moment
                                .utc(formState.authorizationStartDate3.toLocaleString())
                                .format("MM/DD/YYYY")
                              : ""
                          }
                          inputProps={{ readOnly: isPreviousVisits }}
                          onMouseDown={handleFocus}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="MM/DD/YYYY"
                      value={formState.authorizationEndDate3 || ""}
                      onChange={(e) => updateDate(e, 'authorizationEndDate3')}
                      readOnly={isPreviousVisits}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={formState.activeAuthorization3 && !formState.authorizationEndDate3}
                          helperText={formState.activeAuthorization3 && !formState.authorizationEndDate3 ? "required" : ""}
                          value={
                            formState.authorizationEndDate3
                              ? moment
                                .utc(formState.authorizationEndDate3.toLocaleString())
                                .format("MM/DD/YYYY")
                              : ""
                          }
                          inputProps={{ readOnly: isPreviousVisits }}
                          onMouseDown={handleFocus}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              )}

              {formState?.activeAuthorization3 != 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="authorizationDenied3"
                        checked={Boolean(formState.authorizationDenied3)}
                        onChange={isPreviousVisits ? () => { } : updateCheckBox}
                      />
                    }
                    label="Authorization Denied"
                  />
                </Box>
              )}
              {formState?.authorizationDenied3 === 1 && (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    // display: "flex",
                    gap: 1,
                    marginTop: 2,
                  }}
                >
                  <GeneralPulldown
                    key="reasonAuthDenialId3"
                    isPreviousVisits={isPreviousVisits}
                    formState={formState}
                    updateInput={updateInput}
                    formErrorState={formErrorState}
                    attributeName="reasonAuthDenialId3"
                    style={{ width: "100%" }}
                    isPatientPortal={isPatientPortal}
                    pulldownApiName="ReasonAuthDenialPulldown"
                    itemName="reason"
                    labelName="Select Reason For Auth Denial *"
                    dbKeyColumn="reasonAuthDenialId"
                  />

                  <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: "100%" }}>
                    <TextField
                      id="reasonAuthDenial3"
                      {...getErrorValidationProperty("studyDeclinedReason")}
                      label="Notes"
                      variant="standard"
                      onChange={updateInput}
                      value={formState.reasonAuthDenial3}
                      error={
                        formErrorState?.reasonAuthDenial3?.error &&
                        !formState?.reasonAuthDenial3
                      }
                      /* helperText={
                    formErrorState?.reasonAuthDenial?.error &&
                      !formState?.reasonAuthDenial
                      ? "required"
                      : ""
                  } */
                      inputProps={{ readOnly: isPreviousVisits }}
                      onMouseDown={handleFocus}
                    />
                  </FormControl>
                </Box>
              )}
            </Box>
              <Divider sx={{ mt: 2, border: "2px solid rgba(0, 0, 0, 0.12)" }} /> 

          </> :""
        }
        

        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <FormControl sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Follow Up for Authorization Date"
                inputFormat="MM/DD/YYYY"
                value={SDATE || ""}
                onChange={updateSDATE}
                readOnly={isPreviousVisits}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    error={errorSDATE && !SDATE}
                    helperText={errorSDATE && !SDATE ? "required" : ""}
                    value={
                      SDATE
                        ? moment
                          .utc(SDATE.toLocaleString())
                          .format("MM/DD/YYYY")
                        : ""
                    }
                    onBlur={() => {
                      setErrorSDATE(!SDATE);
                    }}
                    inputProps={{ readOnly: isPreviousVisits }}
                    onMouseDown={handleFocus}
                    placeholder="mm/dd/yyyy"
                    sx={{ width: "85%" }}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>

        {!asDialog && !isPreviousVisits && isWellnecessitiesUser && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              data-testid="authorization-save-button"
              sx={{ margin: "1rem 0rem" }}
              disabled={isSaveButtonDisabled}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
      </Box>
    );
  }
}
