import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import roleAndPrivilegesFnc from "../utils/roleAndPriviledges";
import { updatePatientLogin } from "../store/slices/patientLogInDataSlice";
import { useDispatch } from "react-redux";
import { updateSelectedPatient } from "../store/slices/selectedPatientSlice";
import jwt from "jwt-decode";
import createAxiosInstance from "../axios/index";

const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookies, removeCookie] = useCookies();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      window.location.reload();
    }
  }, [isLoading]);

  const MFALogin = async ({ securityCodeUserId, securityCode }) => {
    let res;
    try {
      // setError('')
      const { data } = await axiosInstance.post(`/api/login.php`, {
        securityCodeUserId: securityCodeUserId,
        securityCode: securityCode,
      });

      res = data;
    } catch (ex) {
      setError(`Incorrect login or email.`);
      return;
    }
    if (res && res?.jwt && res?.message === "Successful login.") {
      setCookies("token", res.jwt); // your token

      const { tabPath, tempPassword ,userInfo} = roleAndPrivilegesFnc(res.jwt);

      setIsLoading(true);
      localStorage.setItem('tempPassErr', tempPassword)
      if(userInfo?.emailConfirmed === 0 && userInfo?.phoneNumConfirmed === 0){
        navigate('/profile');
      }else{
        navigate(tabPath || '/dashboard');
      }
    }  else setError(res?.message);
  };
  
  const login = async ({ email, password, emailSecurityCode, textSecurityCode, emailConfirmed, phoneNumConfirmed }) => {
    let res;
    try {
      // setError('')
      const { data } = await axiosInstance.post(`/api/login.php`, {
        email: email,
        password: password,
        emailSecurityCode: emailSecurityCode,
        textSecurityCode: textSecurityCode,
        emailConfirmed: emailConfirmed,
        phoneNumConfirmed: phoneNumConfirmed,
      });

      res = data;
    } catch (ex) {
      setError(`Incorrect login or email.`);
      return;
    }
    if (res && res?.jwt && res?.message === "Successful login.") {
      setCookies("token", res.jwt); // your token
      setCookies('refreshToken', res.refreshToken);
      const { tabPath, tempPassword } = roleAndPrivilegesFnc(res.jwt);

      setIsLoading(true);
      navigate(tabPath || '/dashboard');
      localStorage.setItem('tempPassErr', tempPassword)
    } else if (res?.response === 1 && res?.securityCodeSend === "1") {
      navigate('/authlogin', { state: { securityCodeUserId: res?.securityCodeUserId, sms: res?.sms } })
    } else setError(`Incorrect login or email.`);
  };

  const handlePatientLogIn = async ({ email, password }) => {
    let res;
    try {
      // setError('')
      const { data } = await axiosInstance.post(`/api/patientLogin.php`, {
        email: email,
        password: password,
      });

      res = data;
    } catch (ex) {
      setError(`Incorrect login or email.`);
      return;
    }
    if (res && res?.jwt && res?.message === "Successful login.") {
      setCookies("token", res.jwt); // your token
      setCookies("isPatient", true);

      setIsLoading(false);
      navigate('/patient-portal');

      const { id ,tempPassword } = roleAndPrivilegesFnc(res.jwt);
      dispatch(updatePatientLogin(id));
      localStorage.setItem('tempPassErr', tempPassword)
    } else setError(`Incorrect login or email.`);
  };
  const logout = () => {
    ["token", "isPatient","refreshToken"].forEach((obj) => removeCookie(obj)); // remove data save in cookies
    localStorage.removeItem('lastActivity');
    localStorage.removeItem('patientId');
    localStorage.removeItem('tempPassErr');
    dispatch(updateSelectedPatient(undefined));
    navigate("/signin-landing");
  };

  const axiosInstance = useMemo(() => {
    const instance = createAxiosInstance();
    // Configure the interceptor here
    instance.interceptors.request.use(
       async (config) => {
          const token = cookies.token;
          if (token) {
              const decoded = jwt(token);
              const isExpired = decoded.exp * 1000 < Date.now();
     
            if (isExpired) {
              const refreshToken = cookies.refreshToken;
              const newToken = await refreshAccessToken(refreshToken,cookies.token)
              config.headers['Authorization'] = `Bearer ${newToken}`; 
            } else {
              config.headers['Authorization'] = `Bearer ${token}`;
            }
          }

          return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return instance;
  }, [cookies.token]);

  // Function to refresh access token
  const refreshAccessToken = async (refreshToken) => {
    const instance = createAxiosInstance()
    let res;
    try {
      await instance.post('/api/refreshToken.php', { refreshToken },
      ).then((response) => {
        res = response.data.accessToken
      });
      return res;
    } catch (ex) {
      logout();
    }
  };

  const value = useMemo(
    () => ({
      cookies,
      error,
      login,
      logout,
      handlePatientLogIn,
      setError,
      isLoading,
      MFALogin,
      axiosInstance,
    }),
    [cookies, error]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};

export const useAxios = () => {
  const auth = useAuth();
  // if (auth) {
  //   axios.defaults.headers.common["Authorization"] =
  //     "Bearer " + auth.cookies.token;
  // }

  return auth.axiosInstance;
};
