import dayjs from "dayjs";
import {
  Box,
  Dialog,
  TextField,
  Tooltip
} from "@mui/material";
import { useAuth, useAxios } from "../../../hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import selectedPatientSlice from "../../../store/slices/selectedPatientSlice";
import CustomizedDialogs from "./ScrollDialog";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ToastContainer } from "react-toastify";
import { base64ToPdf } from "../../../utils/base64ToPDFConvertor";
import FileView from "./FileUpload/FileView";
import { Download, Delete, Visibility } from "@mui/icons-material";
import FollowingPhysicianPulldown  from "../../../components/misc/FollowingPhysicianPulldown";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Typography,
  useMediaQuery,
} from "../../../../node_modules/@mui/material/index";
import { useState } from "react";
import * as React from "react";
import { updateSelectedPatient } from "../../../store/slices/selectedPatientSlice";
import PatientSearch from "../../../pages/PatientSearch";
import PatientInfo from "./PatientInfo";
import InsuranceInfo from "./InsuranceInfo";
import PhysicianPulldown from "../../../components/misc/PhysicianPulldown";
import FollowUpPhysicianPulldown from "../../../components/misc/FollowUpPhysicianPulldown";
import phoneNumberValidator from "../../../utils/phoneNumberValidator";
import FileUpload from "./FileUpload/FileUpload";
import SelectedPatients from "./selectPatients/SelectedPatients";
import {
  orderPostData,
  patientsPaginationGetData,
  surveyPostData,
  patientsPostData,
  getHeaderData,
  profilePost,
} from "../../../axios/api";
import SignedOrder from "./SignedOrder";
import useIsPrevileges from "../../../hooks/useRoleAndPrivileges";
import CloseIcon from "@mui/icons-material/Close";
import CloseRejectDialog from "./CloseRejectDialog";
import { roles } from "../../../constants/tabs";
import DateToVisit from "./Order/DateToVisit";
import moment from "moment";
import {
  isCheckOneFaceToFaceNote,
  isChooseISDDiagnosis,
} from "../../../utils/signOrderFnc";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../../utils/notification";
import BMICalculation from "../../../components/patientsComponent/BMICalculation/BMICalculation";
import { readOnlyBackground } from "../../../Theme/theme";
import {
  REJECTED_ORDER,
  SIGNED_ORDER,
} from "../../../constants/visitsPatients";
import Documentupload from "../../../components/adminComponent/documents/documentUpload/Documentupload";
import jsPDF from "jspdf";
import signOrderHTMLString from "../../../utils/signOrderHTMLString";
import roleAndPrivilegesFnc from "../../../utils/roleAndPriviledges";
function OrderView({
  surveyAndOrderId = {},
  isOrderModal = false,
  handleClose = () => {},
  reschedulePopup,
  data,
  followUpOrder = false,
}) {

  const [orderAuthority, setOrderAuthority] = useState(0)
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:800px)");
  const privelegesArray = useIsPrevileges();
  const isSignOrderPresent =
    privelegesArray?.privileges?.subtab?.includes("signOrder");
  const isOrderAuthorityPresent = privelegesArray?.isOrderAuthority;
  const [formState, setFormState] = useState({});
  const [surveyFormState, setSurveyFormState] = useState({});
  const [surveyCheck, setSurveyCheck] = useState({});
  const navigate = useNavigate();
  const [refreshData, setRefreshData] = useState(false);
  const [fallsAsleepCheckBox, setFallsAsleepCheckBox] = useState(false);
  const [viewRejectState, setViewRejectDialogState] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfURLState, setPdfURLState] = useState({ fileUrl: "", fileType: "" });
  const axios = useAxios();
  const {
    cookies: { token },
  } = useAuth();
  const rejectDialog = () => {
    setViewRejectDialogState(false);
    setRefreshData(!refreshData);
  };
  const location = useLocation();
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfURL, setPdfURL] = useState("");
  const { roleAndPriviledge } = roleAndPrivilegesFnc(token);
  const [expanded, setExpanded] = React.useState(false);
  const [patientsPhoneNumber, setPatientsPhoneNumber] = useState({
    bestPhone: "",
    cellPhone: "",
    bestPhoneError: false,
    cellPhoneError: false,
  });
  const download = (id, tableName, idColumn, isView) => {
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    };
    patientsPostData(axios, "downloadFile", payload)
      .then((res) => {
        if (res.data.file) {
          if (isView === "view") {
            setOpenPdfModal(true);
            let base64 = res?.data?.file;
            base64 = base64?.split(",")[1];
            let fileType = res?.data?.name;
            fileType = fileType?.split(".")?.pop();
            const pdfUrl = base64ToPdf(base64, fileType);
            setPdfURLState({
              fileUrl: pdfUrl,
              fileType,
            });
          } else {
            notificationToastSuccess("File is downloading.");
            const downloadLink = document.createElement("a");
            downloadLink.href = res.data.file;
            downloadLink.download = res.data.name;
            downloadLink.click();
          }
        } else {
          notificationToastError("File was not downloaded due to issue.");
        }
        // setRefreshData(!refreshData);
      })
      .catch((err) => notificationToastError(err?.message));
  };
  const updateCheckBox = (e) => {
    setFormState((prevState) => ({
      ...prevState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    }))
    setIsSaveButtonDisabled(false);
  };
  const [formErrorState, setFormErrorState] = useState({
    firstName: "",
    lastName: "",
    physicianId: "",
  });
  const selectedPatient = useSelector(
    (state) => state.selectedPatientSelector?.value
  );
  const [DOB, setDOB] = React.useState(null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isOrderSignedSucess, setIsOrderSignedSucess] = useState(false);
  const [visitDateState, setVisitDate] = useState(new Date());
  const [signOrderOpen, setSignOrderOpen] = useState(false);
  const [BMI, setBMIState] = useState({});
  const surveyIdDashboard = location?.state?.row
    ? location?.state?.row[10]
    : reschedulePopup
    ? data.surveyId
    : null;
   
  const updateSurveyCheck = (e) => {
    const newSurveyState = {
      ...surveyCheck,
      [e.target.id]: e.target.checked ? 1 : 0,
    };
    setIsSaveButtonDisabled(false);
    setFormState((prevState) => ({
      ...prevState,
      ...newSurveyState 
    }))
    setSurveyCheck(newSurveyState);
  };
  const surveyOrderData = reschedulePopup
    ? data
    : location?.state?.surveyOrderData;
    
  React.useEffect(() => {
    const row = reschedulePopup ? data : location?.state?.row;
    const surveyOrderData = reschedulePopup
      ? data
      : location?.state?.surveyOrderData;

      if(row?.[73]){
        profilePost(axios, "checkOrderAuthorityUser", { physicianId: row?.[73] })
            .then((res) => {
                setOrderAuthority(res?.checkOrderAuthority)
            })
            .catch((err) => {
              console.log(err);
            });
      }
 
      if (row) {
     
        setFormState((prevState) => ({
          ...prevState,
          fileId: row[266] || 0,
          statusNameId: row[96],
          physicianName: row[305]
        }))
        setVisitDate(row[294] || row?.visitDate || row?.orderDate)
  
      }
    if (surveyOrderData) {
     
      setFormState((prevState) => ({
        ...prevState,
        ...surveyOrderData,
      }))
      setVisitDate(surveyOrderData?.visitDate || surveyOrderData?.orderDate || new Date())
    }
    let payload = {};
    if (row) {
      if (followUpOrder) {
        payload = {
          patientId: row[0] || surveyOrderData?.patientId,
          physicianId: data[73],
        };
      } else {
        payload = {
          patientId: row[0] || surveyOrderData?.patientId,
          orderId: row[11] || surveyOrderData?.orderId,
          surveyId: row[10] || "",
        };
      }
    } else if (Object.keys(surveyAndOrderId).length) {
      payload = {
        patientId: surveyAndOrderId?.patientId,
        orderId: surveyAndOrderId?.orderId,
        surveyId: surveyAndOrderId?.surveyId,
      };
    } else if (Object.keys(surveyOrderData).length) {
      payload = {
        patientId: surveyOrderData?.patientId,
        orderId: surveyOrderData?.orderId,
        surveyId: surveyOrderData?.surveyId,
      };
    }
    if (payload?.patientId) {
      patientsPaginationGetData(axios, "patientView", payload)
        .then((res) => {
          if (res?.length) {
            const patientData = res[0];
            const {
              formattedPhoneNumber: bestPhone,
              numericPhoneNumber: bestPhoneNumeric,
            } = phoneNumberValidator(patientData?.bestPhone);
            const {
              formattedPhoneNumber: cellPhone,
              numericPhoneNumber: cellPhoneNumeric,
            } = phoneNumberValidator(patientData?.cellPhone);
            setPatientsPhoneNumber({
              ...patientsPhoneNumber,
              bestPhone: bestPhoneNumeric,
              cellPhone: cellPhoneNumeric,
            });
            setFormState((prevState) => ({
              ...prevState,
              ...patientData,
              physicianId:
                res?.[1]?.followingPhysId ||
                row?.[73] || row?.physicianIdOrder ||
                surveyAndOrderId?.physicianId || formState?.physicianId,
              bestPhone,
              cellPhone,
              stageId: row ? row[95] : surveyAndOrderId?.stageId,
            }));
            if (row) setVisitDate(row[294] || row?.visitDate || row?.orderDate || new Date())
            setBMIState({
              ...BMI,
              heightFeet: patientData?.heightFeet,
              heightInches: patientData?.heightInches,
              patBMI: patientData?.patBMI,
              patWeight: patientData?.patWeight,
            });
            setDOB(patientData?.dob);
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {});
      if (payload?.orderId) {
        orderPostData(axios, "viewOrder", payload)
          .then((res) => {
            if (res?.data?.length) {
              const orderDetails = res?.data[0];
              setFormState((prevState) => ({
                ...prevState,
                ...orderDetails,
                physicianId: orderDetails?.physicianId,
                "2nightProtocol": 0,
                splitNightProtocol: 0,
                psgBaselineStudy: 0,
                homeSleepTest: 0,
                cpapTitration: 0,
                bipapTitration: 0,
                bipapStTitration: 0,
                AsvTitration: 0,
                AvapsTitration: 0,
                pediatricSleepStudy: 0,
              }));
              setVisitDate(orderDetails.visitDate || orderDetails.visitDate || new Date())
              let selectedStudyType = "";
              if (orderDetails["2nightProtocol"]) {
                selectedStudyType = "2nightProtocol";
              } else if (orderDetails["splitNightProtocol"]) {
                selectedStudyType = "splitNightProtocol";
              } else if (orderDetails["psgBaselineStudy"]) {
                selectedStudyType = "psgBaselineStudy";
              } else if (orderDetails["homeSleepTest"]) {
                selectedStudyType = "homeSleepTest";
              } else if (orderDetails["cpapTitration"]) {
                selectedStudyType = "cpapTitration";
              } else if (orderDetails["bipapTitration"]) {
                selectedStudyType = "bipapTitration";
              } else if (orderDetails["bipapStTitration"]) {
                selectedStudyType = "bipapStTitration";
              } else if (orderDetails["AsvTitration"]) {
                selectedStudyType = "AsvTitration";
              } else if (orderDetails["AvapsTitration"]) {
                selectedStudyType = "AvapsTitration";
              } else if (orderDetails["pediatricSleepStudy"]) {
                selectedStudyType = "pediatricSleepStudy";
              }
              if (selectedStudyType) {
                setSelectedCheckbox(selectedStudyType);
              }
              
            }
          })
          .catch((err) => {});
      }
    }
    if (surveyIdDashboard || surveyAndOrderId?.surveyId) {
      surveyPostData(axios, "viewSurvey", payload)
        .then((res) => {
          if (res?.data?.length) {
            const surveyData = res?.data[0];
           
            setSurveyFormState({
              ...surveyFormState,
              ...surveyData,
            });
            setFormState((prevState) => ({
              ...prevState,
              interpPhysId: surveyData?.physicianId
          }))
          
         }})
        .catch((err) => {});
    }
  }, []);
    
 
  const updateInput = (e) => {
    setFormState((prevState) => ({
      ...prevState,
      [e.target.id || e.target.name]: e.target.value, 
    }))
  
    setIsSaveButtonDisabled(false);
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }
  const openRejectDialog = () => {
    setViewRejectDialogState(true);
  };
  const reject = (reason) => {
    const payload = {
      stageId: formState.stageId,
      reason,
    };
    orderPostData(axios, "rejectOrder", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => notificationToastError(err?.message));
    setViewRejectDialogState(false);
    setTimeout(() => {
      navigate(`/dashboard`);
    }, 2000);
  };
  const saveOrder = (e) => {
  
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }
    if (!DOB) {
      isValid = false;
      setErrorDOB(true);
    }
    if (formState?.otherFaceNotes && !formState?.otherNotes) {
      isValid = false;
      notificationToastError("Please fill a required field 'Other' . ");
      setFormErrorState(errors);
      return false;
    }
    if(selectedCheckbox === "pediatricSleepStudy" && !formState?.pediatricOptions){
      isValid = false;
      notificationToastError("Please select atleast one pediatric option ");
      setFormErrorState(errors);
      return false;
    }
    if (!isValid) {
      notificationToastError("Please fill all required fields.");
      setFormErrorState(errors);
      return false;
    } else {
      // setErrorMessage("");
    }
    if (!isCheckOneFaceToFaceNote(formState)) {
      isValid = false;
      notificationToastError(
        "Please make a selection for FACE TO FACE NOTES (✔ all that apply)"
      );
    }
    if (!selectedCheckbox) {
      isValid = false;
      notificationToastError("Please choose at least one Study Type");
    }
    if (!isChooseISDDiagnosis(formState)) {
      isValid = false;
      notificationToastError(
        "Please choose at least one ICD-10 Diagnosis Code"
      );
    }
    if(moment(new Date(visitDateState)).format("YYYY/MM/DD") == "Invalid date"){
      isValid = false;
      notificationToastError( "Invalid date format");
    }
    if (isValid) {
      setIsSaveButtonDisabled(true);
      let visitDate =
        visitDateState && moment.utc(new Date(visitDateState)).format("YYYY-MM-DD");
        
      let payload;
      if (followUpOrder) {
        payload = {
          ...formState,
          orderId:"",
          physicianId: formState?.physicianId,
          visitDate: visitDate,
          fallsAsleepCheckBox: Number(fallsAsleepCheckBox),
          [selectedCheckbox]: selectedCheckbox ? 1 : 0,
          cellPhone: patientsPhoneNumber?.cellPhone,
          bestPhone: patientsPhoneNumber?.bestPhone,
          parentOrderCpapId: data?.orderId,
          orderFollowingPhysicianId: formState?.physicianId,
          studyId: data?.studyId,
          followUpOrder: 1
        };
      } else {
        payload = {
          ...formState,
          physicianId: formState?.physicianId,
          visitDate: visitDate,
          fallsAsleepCheckBox: Number(fallsAsleepCheckBox),
          [selectedCheckbox]: selectedCheckbox ? 1 : 0,
          cellPhone: patientsPhoneNumber?.cellPhone,
          bestPhone: patientsPhoneNumber?.bestPhone,
          parentOrderCpapId: data?.[11],
          ssn: undefined
        };
      }
      orderPostData(axios, "saveOrder", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            if (e.target.id !== "signOrderBtn") {
              notificationToastSuccess(res?.data?.message);
              if (followUpOrder){
                handleClose();
              } else{
                setTimeout(() => {
                  navigate(`/dashboard`);
                }, 2000);
              }
            } else if (e.target.id === "signOrderBtn") {
              setSignOrderOpen(true);
            }
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((err) => notificationToastError(err?.message));
    }
  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const updateDOB = (newValue) => {
    setDOB(newValue);
  };
  const [errorDOB, setErrorDOB] = React.useState(false);
  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }
  const [isSelectPatient, setIsSelectPatient] = useState(true);
  const unselectPatient = () => {
    dispatch(updateSelectedPatient(undefined));
    setFormState({});
    setDOB(null);
    setIsSelectPatient(true);
  };

  const generatePdf = (signature, headerDetail, headerLogo, action) => {
    let htmlString;
    let inssuranceData = {
      primaryInsurance : formState.primaryInsuranceName,
      secondaryInsurance : formState.secondaryInsuranceName
    }
   
    htmlString = signOrderHTMLString({
      signature,
      formState,
      visitDateState,
      fallsAsleepCheckBox,
      inssuranceData,
      physicianName: formState?.physicianName,
      selectedCheckbox,
      headerDetail,
      headerLogo,
      action
    });

    // const tempDiv = document.createElement('div');
    // // Ensure styles are applied; for example, ensure it's not `display: none` or similar
    // tempDiv.style.visibility = 'hidden';
    // tempDiv.style.position = 'absolute';
    // tempDiv.innerHTML = htmlString;

    // document.body.appendChild(tempDiv);

    const pdf = new jsPDF("p", "pt", "a3");
    pdf.html(htmlString, {
      callback: function (file) {
        let dataUrl = pdf.output("datauristring");
        dataUrl = dataUrl?.split(";");
        dataUrl = [dataUrl[0], dataUrl[2]]?.join(";");
        if (dataUrl) {
          if (!action) handleSignedFileUpload(dataUrl);
          let base64 = pdf.output("datauristring");
          base64 = base64?.split(",")[1];
          const pdfUrl = base64ToPdf(base64);
          setPdfURL(pdfUrl);
          // setOpenPdf(true);
          let fileName;
          fileName = `order_${formState.patientNum}_${visitDateState}.pdf`;
          const link = document.createElement("a");
          link.href = pdfUrl;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // revoke the temporary URL to free up memory
          URL.revokeObjectURL(pdfUrl);
        }
      },
      html2canvas: {
        scale: 0.8,
      },
      margin: [10, 10],
    });
  };

  const downloadPdf = (signature, action) => {

    getHeaderData(axios, "getOrderHeader", { orderId: formState.orderId })
      .then((response) => {
      
        if (response) {
          if (response[0][0]?.logoFileId !== null) {
        
            patientsPostData(axios, "downloadFile", {
              id: response[0][0]?.logoFileId, tableName: "storedFiles", idColumn: "fileId",
            }).then((imgres) => {
              if (imgres.data.file) {
                generatePdf(signature, response, imgres?.data?.file, action);
              } else {
                notificationToastError("File was not downloaded due to issue.");
              }
            }).catch((err) => notificationToastError(err?.message))
          } else {
        
            generatePdf(signature, response, "", action);
          }
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      })
  };

  const handleSignedFileUpload = (dataUrl) => {
    let fileName;
    fileName = `SignedOrder.pdf`;
    const payload = {
      file: dataUrl,
      fileName: fileName,
      patientId: selectedPatient?.patientId || formState.patientId,
      signedOrder: 1,
      stageId: formState.stageId,
      orderId: formState.orderId,
      dmeOrderId: formState.dmeOrderId,
    };
    patientsPostData(axios, "uploadFile", payload)
      .then((res) => {
        if (res.data.response === "1") {
          // notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => notificationToastError(err?.message))
      .finally(() => handleClose());
  };
  const handleCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    if(event.target.checked){
      setSelectedCheckbox(checkboxId);
    }else{
      setSelectedCheckbox("");
    }
    if (checkboxId === "2nightProtocol") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: event.target.checked ? Number(true) : Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "splitNightProtocol") {
      setFormState({
        ...formState,
        splitHSTSubstitute: event.target.checked ? Number(true) : Number(false),
        ["2nightHSTSubstitute"]: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "psgBaselineStudy") {
      setFormState({
        ...formState,
        psgOptions: event.target.checked ?
          "SUBSTITUTE HST if in-lab disqualified by insurance or denied OR," : "",
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "homeSleepTest") {
      setFormState({
        ...formState,
        hstNights: event.target.checked ? "TWO NIGHT" :"",
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        cpapIfHstAbnormal: event.target.checked ? Number(true) : Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "cpapTitration") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
        cpapSubstitute: event.target.checked ? formState.cpapSubstitute : ""
      });
    } else if (checkboxId === "bipapTitration") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "bipapStTitration") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "AsvTitration") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "AvapsTitration") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "pediatricSleepStudy") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: event.target.checked ? "PSG BASELINE STUDY (95782 or 95810 Medicaid only)" : "",
      });
    }
    setIsSaveButtonDisabled(false);
  };
  // homeSleepTest
  // homeSleepTestSubstitute
  // console.log("formState==>", formState);
  if (formState?.tired) formState.fatigue = 1;
  if (formState?.tired) formState.daytimeSleepiness = 1;
  if (formState?.choking) formState.apneas = 1;
  if (
    formState?.stopbangScore > 2 &&
    formState?.patientBMI &&
    formState?.usingCpap != 1
  ) {
    formState.splitHSTSubstitute = 1;
    formState.splitNightProtocol = 1;
    formState.G47_30 = 1;
  }
  if (
    formState?.stopbangScore > 2 &&
    formState?.patientBMI &&
    formState?.usingCpap
  ) {
    formState.cpapTitration = 1;
    formState.G47_30 = 1;
  }

  let orderFollowing;
  if (formState?.orderFollowingPhysicianId > 0) orderFollowing = true;
  else orderFollowing = false;
  return (
    <Container
      maxWidth="lg"
      sx={{ margin: isOrderModal && !isMobile ? "30px 10px" : "0px" }}
    >
      {selectedPatient && !isOrderModal && (
        <SelectedPatients
          selectedPatient={selectedPatient}
          unselectPatient={unselectPatient}
        />
      )}
      {!isOrderModal && <FileUpload patientId={selectedPatient?.patientId} />}
      {(!isOrderModal &&  formState?.fileId > 0) &&  (
   
        <Box sx={{ display: "flex",alignItems:"center", gap: 1, marginTop: "1rem" }}>
          <Typography
            variant="h6" > View Interpretation </Typography>
          <Tooltip title="View" placement="bottom">
            <Button
              sx={{ width: 60,mr:3 }}
              variant="contained" 
              size="small"
              onClick={download.bind(
                null,
                formState?.fileId,
                "storedFiles",
                "fileId",
                "view"
              )}
            >
              <Visibility />
            </Button>
          </Tooltip>
          {
            location.state.row[238] > 0 &&
            <>
            <Typography
            variant="h6" > Download Order </Typography>
             <Button
              sx={{ width: 60,mr:3 }}
              variant="contained"
              size="small"
              onClick={downloadPdf}
            >
              <Download />
            </Button>
              <Documentupload
                data-testid="document-upload" // Add Cypress ID to the Documentupload component
                setFormState={setFormState}
                setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                formErrorState={formErrorState}
                setFormErrorState={setFormErrorState}
                formState={formState}
                title="Upload Signed Order"
              />
            </>
          }
        </Box>
                 
      )}
      {openPdfModal && (
          <FileView
            setOpenPdfModal={setOpenPdfModal}
            openPdfModal={openPdfModal}
            pdfURLState={pdfURLState}
          />
        )}
      {isOrderModal && (
        <Box
          data-testid="close-button"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: "20px",
            top: "10px",
            background: "#cdcfcf",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <CloseIcon sx={{ color: "#fff" }} />
        </Box>
      )}
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "20px",
          background: readOnlyBackground,
        }}
        noValidate
        autoComplete="off"
      >
        <PatientInfo
          formState={formState}
          updateInput={updateInput}
          DOB={DOB}
          updateDOB={updateDOB}
          errorDOB={errorDOB}
          setErrorDOB={setErrorDOB}
          formErrorState={formErrorState}
          setFormErrorState={setFormErrorState}
          isReadOnly={true}
          handleFocus={handleFocus}
          BMIState={BMI}
        />
        <InsuranceInfo
          formState={formState}
          setFormState={setFormState}
          isReadOnly={true}
          handleFocus={handleFocus}
        />
      </Box>
      {(surveyAndOrderId?.surveyId || surveyIdDashboard) && (
        <Box
          sx={{
            border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            margin: "10px 0px 20px 0px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <BMICalculation
            formState={formState}
            updateInput={updateInput}
            formErrorState={formErrorState}
            validateEmptyField={validateEmptyField}
            expanded={expanded}
            setExpanded={setExpanded}
            handleFocus={handleFocus}
            surveyFormState={surveyFormState}
            surveyCheck={surveyCheck}
          />
        </Box>
      )}
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            paddingLeft: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          FACE TO FACE NOTES (check all that apply)
        </Typography>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="snoring"
                  checked={Boolean(formState.snoring)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Snoring"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="fatigue"
                  checked={Boolean(formState.fatigue)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Fatigue"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="daytimeSleepiness"
                  checked={Boolean(formState.daytimeSleepiness)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Excessive Daytime Sleepiness"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="gasping"
                  checked={Boolean(formState.gasping)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Wakes up gasping for breath"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="apneas"
                  checked={Boolean(formState.apneas)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Witnessed Apneas"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="inappropriateSleep"
                  checked={Boolean(formState.inappropriateSleep)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Falls asleep at inappropriate times and/or places"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="otherFaceNotes"
                  checked={Boolean(formState.otherFaceNotes)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Other"
            />
          </FormControl>
        </div>
        {formState?.otherFaceNotes == 1 && (
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <TextField
              id="otherNotes"
              label="Other *"
              value={formState.otherNotes}
              variant="standard"
              onChange={updateInput}
              inputProps={{ maxLength: 200 }}
            />
          </Box>
        )}
        <Box>
          <DateToVisit
            setVisitDate={setVisitDate}
            visitDateState={visitDateState}
            setIsSaveButtonDisabled={setIsSaveButtonDisabled}
          />
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          "& .MuiBox-root": { flexGrow: 1 },
          display: "flex",
          gap: "2rem",
          marginTop: "0.5rem",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              paddingLeft: "0.5rem",
              paddingTop: "1rem",
            }}
          >
            PLEASE CHOOSE ONE STUDY TYPE (required)
          </Typography>
          <FormControl sx={{ m: 1 }}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="2nightProtocol"
                    // checked={twoNightProtocolCheckBox}
                    // onChange={() => {
                    //   setTwoNightProtocolCheckBox(!twoNightProtocolCheckBox);
                    //   setIsSaveButtonDisabled(false);
                    // }}
                    checked={(selectedCheckbox === "2nightProtocol" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="2 NIGHT PROTOCOL - 1ST Polysomnogram (PSG) THEN 2nd night CPAP trial, if indicated (95810/95811)"
              />
            </Box>
            <Box>
              <FormControlLabel
                sx={{ paddingLeft: "2rem" }}
                value="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
                control={
                  <Checkbox
                    id="2nightHSTSubstitute"
                    checked={Boolean(formState["2nightHSTSubstitute"])}
                    onChange={updateCheckBox}
                  />
                }
                label="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="splitNightProtocol"
                    checked={(selectedCheckbox === "splitNightProtocol" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="SPLIT NIGHT PROTOCOL - 1ST night SPLIT - if PAP not initiated, THEN 2nd night CPAP/BiPAP (95810/95811)"
              />
            </Box>
            <Box>
              <FormControlLabel
                sx={{ paddingLeft: "2rem" }}
                value="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
                control={
                  <Checkbox
                    id="splitHSTSubstitute"
                    checked={Boolean(formState.splitHSTSubstitute)}
                    onChange={updateCheckBox}
                  />
                }
                label="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="psgBaselineStudy"
                    checked={(selectedCheckbox === "psgBaselineStudy" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="PSG baseline study (95810)"
              />
            </Box>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="psgOptions"
              id="psgOptions"
              sx={{ paddingLeft: "2rem" }}
              onClick={(e) => {
                if (e.target.value === formState?.psgOptions) {
                  setFormState({ ...formState, psgOptions: null });
                } else updateInput(e);
              }}
              value={formState?.psgOptions || ""}
              onChange={updateInput}
            >
              <Box>
                <FormControlLabel
                  value="SUBSTITUTE HST if in-lab disqualified by insurance or denied OR,"
                  control={<Radio />}
                  label="SUBSTITUTE HST if in-lab disqualified by insurance or denied OR,"
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="PSG with MSLT (Multiple Sleep Latency Test) 95805"
                  control={<Radio />}
                  label="PSG with MSLT (Multiple Sleep Latency Test) 95805"
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="PSG with MWT (Maintenance of Wakefulness Test) 95805"
                  control={<Radio />}
                  label="PSG with MWT (Maintenance of Wakefulness Test) 95805"
                />
              </Box>
            </RadioGroup>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="homeSleepTest"
                    checked={(selectedCheckbox === "homeSleepTest" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="HOME SLEEP TEST"
              />
            </Box>
            <Box sx={{ paddingLeft: "2rem" }}>
              {formState && (
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="hstNights"
                  id="hstNights"
                  onClick={(e) => {
                    if (e.target.value === formState?.hstNights) {
                      setFormState({ ...formState, hstNights: null });
                    } else updateInput(e);
                  }}
                  value={formState?.hstNights || ""}
                  sx={{
                    flexDirection: "row",
                  }}
                >
                  <Box>
                    <FormControlLabel
                      value="ONE NIGHT"
                      control={<Radio />}
                      label={"ONE NIGHT"}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      value="TWO NIGHT"
                      control={<Radio />}
                      label="TWO NIGHT"
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      value="THREE NIGHT"
                      control={<Radio />}
                      label="THREE NIGHT"
                    />
                  </Box>
                </RadioGroup>
              )}
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="cpapIfHstAbnormal"
                      checked={Boolean(formState.cpapIfHstAbnormal)}
                      onChange={updateCheckBox}
                    />
                  }
                  sx={{ marginLeft: "2rem" }}
                  label="IF abnormal HST then CPAP titration (95811)"
                />
              </Box>
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="cpapTitration"
                    checked={(selectedCheckbox === "cpapTitration" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="CPAP titration (95811)"
              />
            </Box>
            {selectedCheckbox === "cpapTitration" && (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="cpapOptions"
                onClick={(e) => {
                  if (e.target.value === formState?.cpapOptions) {
                    setFormState({ ...formState, cpapOptions: null });
                  } else updateInput(e);
                }}
                value={formState?.cpapOptions || ""}
                onChange={updateInput}
                sx={{ paddingLeft: "2rem" }}
              >
                <Box>
                  <FormControlLabel
                    value="MSLT (Multiple Sleep Latency Test) 95805"
                    control={<Radio />}
                    label="MSLT (Multiple Sleep Latency Test) 95805"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    value="MWT (Maintenance of Wakefulness Test) 95805"
                    control={<Radio />}
                    label="MWT (Maintenance of Wakefulness Test) 95805"
                  />
                </Box>
              </RadioGroup>
            )}
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="bipapTitration"
                    checked={(selectedCheckbox === "bipapTitration" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="BiPAP titration (95811)"
              />
            </Box>
            {selectedCheckbox === "bipapTitration" && (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="bipapOptions"
                onClick={(e) => {
                  if (e.target.value === formState?.bipapOptions) {
                    setFormState({ ...formState, bipapOptions: null });
                  } else updateInput(e);
                }}
                value={formState?.bipapOptions || ""}
                onChange={updateInput}
                sx={{ paddingLeft: "2rem" }}
              >
                <Box>
                  <FormControlLabel
                    value="MSLT (Multiple Sleep Latency Test) 95805"
                    control={<Radio />}
                    label="MSLT (Multiple Sleep Latency Test) 95805"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    value="MWT (Maintenance of Wakefulness Test) 95805"
                    control={<Radio />}
                    label="MWT (Maintenance of Wakefulness Test) 95805"
                  />
                </Box>
              </RadioGroup>
            )}
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="bipapStTitration"
                    checked={(selectedCheckbox === "bipapStTitration" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="BiPAP ST titration (95811)"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="AsvTitration"
                    checked={(selectedCheckbox === "AsvTitration" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="ASV titration (95811)"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="AvapsTitration"
                    checked={(selectedCheckbox === "AvapsTitration" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="AVAPS titration (95811)"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="pediatricSleepStudy"
                    checked={(selectedCheckbox === "pediatricSleepStudy" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="PEDIATRICS SLEEP STUDY (5 YEARS OF AGE OR YOUNGER)"
              />
            </Box>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="pediatricOptions"
              onClick={(e) => {
                if (e.target.value === formState?.pediatricOptions) {
                  setFormState({ ...formState, pediatricOptions: null });
                } else updateInput(e);
              }}
              value={formState?.pediatricOptions || ""}
              onChange={updateInput}
              sx={{ paddingLeft: "2rem" }}
            >
              <Box>
                <FormControlLabel
                  value="PSG BASELINE STUDY (95782 or 95810 Medicaid only)"
                  control={<Radio />}
                  label="PSG BASELINE STUDY (95782 or 95810 Medicaid only)"
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="CPAP titration (95783 or 95811 Medicaid only)"
                  control={<Radio />}
                  label="CPAP titration (95783 or 95811 Medicaid only)"
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="BiPAP titration (95783 or 95811 Medicaid only)"
                  control={<Radio />}
                  label="BiPAP titration (95783 or 95811 Medicaid only)"
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              paddingLeft: "0.5rem",
              paddingTop: "1rem",
            }}
          >
            PLEASE CHOOSE AT LEAST ONE ICD-10 DIAGNOSIS CODE (required)
          </Typography>
          <FormControl sx={{ m: 1 }}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_30"
                    checked={Boolean(formState.G47_30)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.30 Sleep apnea, unspecified"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_33"
                    checked={Boolean(formState.G47_33)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.33 Obstructive sleep apnea"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_31"
                    checked={Boolean(formState.G47_31)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.31 Primary Central Sleep Apnea"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_9"
                    checked={Boolean(formState.G47_9)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.9 Sleep disorder, unspecified"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="E66_2"
                    checked={Boolean(formState.E66_2)}
                    onChange={updateCheckBox}
                  />
                }
                label="E66.2 Morbid (severe) obesity alveolar hypoventilation"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_69"
                    checked={Boolean(formState.G47_69)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.69 Other sleep related movements"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_61"
                    checked={Boolean(formState.G47_61)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.61 Periodic limb movement disorders"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="F51_12"
                    checked={Boolean(formState.F51_12)}
                    onChange={updateCheckBox}
                  />
                }
                label="F51.12 Insufficient sleep syndrome"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_11"
                    checked={Boolean(formState.G47_11)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.11 Idiopathic hypersomnia with long sleep time"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_12"
                    checked={Boolean(formState.G47_12)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.12 Idiopathic hypersomnia without long sleep time"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_13"
                    checked={Boolean(formState.G47_13)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.13 Recurrent hypersomnia"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_19"
                    checked={Boolean(formState.G47_19)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.19 Other hypersomnia"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="F51_4"
                    checked={Boolean(formState.F51_4)}
                    onChange={updateCheckBox}
                  />
                }
                label="F51.4 Sleep terrors (night terrors)"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="F51_5"
                    checked={Boolean(formState.F51_5)}
                    onChange={updateCheckBox}
                  />
                }
                label="F51.5 Nightmare disorders"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="F51_3"
                    checked={Boolean(formState.F51_3)}
                    onChange={updateCheckBox}
                  />
                }
                label="F51.3 Sleepwalking (Somnambulism)"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_411"
                    checked={Boolean(formState.G47_411)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.411 Narcolepsy with cataplexy"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_419"
                    checked={Boolean(formState.G47_419)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.419 Narcolepsy without cataplexy"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_421"
                    checked={Boolean(formState.G47_421)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.421 Narcolepsy in conditions classified elsewhere, with cataplexy"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_421_wo_cataplexy"
                    checked={Boolean(formState.G47_421_wo_cataplexy)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.421 Narcolepsy in conditions classified elsewhere, without cataplexy"
              />
            </Box>
          </FormControl>
        </Box>
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="addlInstructions"
            label="Special/Additional Instructions:"
            value={formState.addlInstructions || ""}
            variant="standard"
            onChange={updateInput}
            inputProps={{ maxLength: 200 }}
          />
        </Box>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="consult"
                  checked={Boolean(formState.consult)}
                  onChange={updateCheckBox}
                />
              }
              label="CONSULT AND TREAT BY BOARD CERTIFIED SLEEP PHYSICIAN"
            />
          </FormControl>
        </div>
       
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "98%" },
              "& .MuiFormControl-root": { flexGrow: 1 },
              // border: "1px solid rgba(0,0,0, 0.12)",
              borderRadius: "4px",
              margin: "10px 0px 20px 0px",
              display: "flex",
            }}
          >
            {orderFollowing && (
              <FollowUpPhysicianPulldown
                formState={formState}
                updateInput={updateInput}
                attributeName={"physicianId"}
                formErrorState={formErrorState}
                validateEmptyField={validateEmptyField}
              />
            )}
            {followUpOrder && (
            
            <FollowingPhysicianPulldown
              formState={formState}
              updateInput={updateInput}
              attributeName={"physicianId"}
              formErrorState={formErrorState}
              validateEmptyField={validateEmptyField}
              followUpOrder = {true}
            />
          )}
            {(!orderFollowing && !followUpOrder) && (
              <PhysicianPulldown
                formState={formState}
                updateInput={updateInput}
                attributeName={"physicianId"}
                formErrorState={formErrorState}
                validateEmptyField={validateEmptyField}
              />
            )}
          </Box>
        
          {( formState?.statusNameId === 1 && !isOrderSignedSucess  ) &&  (
          <Box m={2}>
          <Documentupload
                 data-testid="document-upload" // Add Cypress ID to the Documentupload component
                 setFormState={setFormState}
                 setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                 formErrorState={formErrorState}
                 setFormErrorState={setFormErrorState}
                 formState={formState}
                 title={"Upload Signed Order"}
             />
             </Box>
          )}
      </Box>
      {surveyAndOrderId?.stages?.some((arr) => arr.includes(SIGNED_ORDER)) ||
      surveyOrderData?.stages?.some((arr) => arr.includes(SIGNED_ORDER)) && !followUpOrder ? (
     
        <Box
          sx={{ margin: "20px 0px", maxWidth: "251px", paddingBottom: "20px" }}
        >
          <Alert variant="filled" severity="success">
            This order has been signed
          </Alert>
        </Box>
      ) : surveyAndOrderId?.stages?.some((arr) =>
          arr.includes(REJECTED_ORDER)
        ) ||
        surveyOrderData?.stages?.some((arr) => arr.includes(REJECTED_ORDER)) ? (
        <Box
          sx={{ margin: "20px 0px", maxWidth: "251px", paddingBottom: "20px" }}
        >
          <Alert variant="filled" severity="error">
            This order has been rejected
          </Alert>
        </Box>
      ) : (
        formState.statusNameId !== 4 && (
          <>          
            {!isOrderSignedSucess ? (
              <Box
                sx={{
                  paddingTop: "2rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  gap: "15px",
                }}
              >
                <Button
                  variant="contained"
                  disabled={isSaveButtonDisabled}
                  onClick={saveOrder}
                >
                  SAVE ORDER
                </Button>
                
                 {(isSignOrderPresent || orderAuthority == 1) && (
                  <SignedOrder
                    formState={formState}
                    fallsAsleepCheckBox={fallsAsleepCheckBox}
                    setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                    visitDateState={visitDateState}
                    setIsOrderSignedSucess={setIsOrderSignedSucess}
                    saveOrder={saveOrder}
                    setOpen={setSignOrderOpen}
                    open={signOrderOpen}
                    selectedCheckbox={selectedCheckbox}
                    isOrderAuthorityPresent = {isOrderAuthorityPresent}
                    physicianName = {formState?.physicianName}
                    physicianId = {formState?.physicianId}
                  />
                )}
              
                <Button variant="contained" onClick={openRejectDialog}>
                  Reject ORDER
                </Button>
                {(formState?.statusNameId === 1) &&  (

<Button
 variant="contained"
 size="small"
 onClick={downloadPdf.bind(null, "signature", "download")}
>
 Download Order 
</Button>


)}
              </Box>
            ) : (
              <Box sx={{ padding: "20px 0px", maxWidth: "251px" }}>
                <Alert variant="filled" severity="success">
                  This order has been signed
                </Alert>
              </Box>
            )}
          </>
        )
      )}
      {formState.statusNameId === 4 && (
        <div style={{ paddingTop: "2rem" }}>Order was Rejected.</div>
      )}
      {viewRejectState && (
        <CloseRejectDialog
          handleSave={reject}
          handleClose={rejectDialog}
          {...viewRejectState}
        />
      )}
      <ToastContainer />
    </Container>
  );
}
export default OrderView;
